import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';

const useStyles = makeStyles({
  card: {
    background: 'none',
    boxShadow: 'none',
    padding: '.4rem',
  },
  card__disabled: {
    background: '#e6e6e6 !important',
  },
  icon: {
    position: 'relative',
    height: '8rem',
  },
  card_content: {
    borderRadius: '1rem',
    height: '5.4rem',
    textAlign: 'center',
    background: 'white',
    boxShadow: '.1rem .3rem .6rem 0px #ccc',
  },
  icon__svg: {
    position: 'absolute',
    bottom: '.2rem',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  card__percentage: {
    marginBottom: '.2rem',
    fontWeight: 'bold',
    fontSize: '2.2rem',
  },
  card__cost: {
    color: '#AFAFAF',
    fontSize: '1.2rem',
  },
  percentage__sybmol: {
    fontSize: '1.8rem',
  },
});

type Props = {
  percentage: number,
  cost: string,
  clicked: any,
  option: string,
  activeClass: string,
  icon?: string,
  height?: number,
  disableCard: boolean,
};

const SingleCard: React.FC<Props> = ({ percentage, cost, clicked, option, activeClass, icon, height, disableCard }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      {
        icon && (
          <div className={classes.icon}>
            <img
              className={classes.icon__svg}
              src={require(`assets/${icon}.svg`)}
              height={height}
              alt=""
            />
          </div>
        )
      }
      <CardActionArea
        className={`${classes.card_content} ${option === activeClass && 'card__active'} ${disableCard && classes.card__disabled}`}
        onClick={!disableCard ? clicked : null}
      >
        <div className={classes.card__percentage}>
          {percentage}
          <span className={classes.percentage__sybmol}>%</span>
        </div>
        {cost && <div className={classes.card__cost}>{cost} {t('currency')}</div>}
      </CardActionArea>
    </Card>
  );
}

export default SingleCard;
