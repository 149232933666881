import React, { useContext } from 'react';
import UserConsumer from 'context/user/User';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import Scrollbars from 'react-custom-scrollbars';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/styles';

import styles from './Terms.module.scss';
import { useTranslation } from 'react-i18next';

const CssSwipeableDrawer = withStyles({
  root: {
    '& .MuiDrawer-paper': {
      width: '95%',
      display: 'grid',
      // gridTemplateColumns: 'auto min-content',
      gridTemplateAreas: `"title close" "terms terms"`,
      alignItems: 'start',
      overflow: 'hidden',
    },
  },
})(SwipeableDrawer);

const CssIconButton = withStyles({
  root: {
    margin: '1rem .4rem',
    color: '#404040',
    position: 'absolute',
    justifySelf: 'end',
  },
})(IconButton);

const CssCloseIcon = withStyles({
  root: {
    fontSize: '3rem',
  },
})(CloseIcon);

type Props = {
  terms: boolean,
  toggleTerms: () => void,
};

type ContextProps = {
  currentUser: {
    userId: string,
    isLoggedIn: boolean,
    iOS: boolean,
    toggleTerms: () => void,
    terms: boolean,
  },
};

const Terms = ({toggleTerms, terms}: Props) => {
  const userConsumer = useContext<Partial<ContextProps>>(UserConsumer);
  const { currentUser: { iOS } }: any = userConsumer;
  const { t } = useTranslation();

  // Add translations with html tags by dangerouslySetInnerHTML
  const createMarkup: any = () => ({__html: t('terms_text')});

  return (
    <CssSwipeableDrawer
      anchor="right"
      open={terms}
      onClose={() => toggleTerms()}
      onOpen={() => null}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      disableSwipeToOpen
    >
        <div className={styles.terms_termsTitle}>Regulamin</div>
        <CssIconButton aria-label="close" style={{gridArea: 'close'}} onClick={toggleTerms}>
          <CssCloseIcon />
        </CssIconButton>
        <Scrollbars thumbSize={90} style={{width: 'auto', height: 'calc(100vh - 6rem)'}} className={styles.terms_termsText}>
          <div dangerouslySetInnerHTML={createMarkup()} />
        </Scrollbars>
    </CssSwipeableDrawer>
  );
};

export default Terms;
