import { withStyles } from '@material-ui/core/styles';
import { SelectProps } from '@material-ui/core/Select';

export const SelectStyle = (component: React.ComponentType<SelectProps>) => withStyles({
  '@global': {
    '.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #E9649B',
    },
    '.MuiFormHelperText-root.Mui-error': {
      color: '#E9649B',
      margin: '.5rem 0 0 0',
      fontSize: '1.2rem',
      fontFamily: 'Montserrat',
    },
  },
  root: {
    color: '#c6c6c6',
    background: 'white',
    padding: '1rem',
    height: '2rem',
    lineHeight: '2rem',
    fontSize: '1.4rem',
    fontFamily: "'Montserrat', sans-serif",
  },
  select: {
    '&:focus': {
      background: 'white',
    },
    '&:disabled': {
      color: '#CDD8EF',
    },
  },
  icon: {
    top: 'inherit',
    fontSize: '2.5rem',
  },
})(component);
