import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import logoImage from 'assets/logo.png';
import borderImage from 'assets/border.png';
import fontMedium from 'assets/font/Montserrat-Medium.ttf';

// Register font
Font.register({ family: 'Montserrat', fonts: [
  { src: fontMedium },
 ]});

// Disable hyphenation
Font.registerHyphenationCallback((word) => [word]);

// Create styles
const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    fontFamily: 'Montserrat',
    flexWrap: 'wrap',
    fontSize: 9,
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    height: 165,
    padding: '0pt 10pt',
    borderRight: '1pt dashed grey',
    borderTop: '1pt dashed grey',
  },
  border: {
    position: 'absolute',
    top: 5,
    left: 10,
    width: '100%',
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  leftColumn: {
    display: 'flex',
    textAlign: 'left',
    flexDirection: 'column',
    width: '60%',
    padding: '20pt 15pt 20pt 15pt',
  },
  textHeader: {
    margin: '6pt 0pt 5pt 0pt',
  },
  textName: {
    color: '#913581',
    fontSize: 10,
  },
  textMain: {
    marginBottom: 5,
  },
  textInfo: {
    marginBottom: 5,
  },
  rightColumn: {
    display: 'flex',
    paddingTop: 27,
    flex: 1,
  },
  codeSection: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  qrCode: {
    width: '90pt',
    height: '90pt',
  },
  logoSection: {
    textAlign: 'left',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 3,
  },
  logoText: {
    color: '#404040',
    width: 'auto',
    fontSize: 5,
  },
  logo: {
    width: 25,
    marginLeft: 2,
  },
});

// TODO: This text should be provided by backend in such pieces
const backendText = {
  header: 'Witaj',
  main: 'Jeżeli jesteś zadowolony z obsługi przyznaj mi napiwek za pomocą tego kodu QR.',
  info: 'Zeskanuj kod kreskowy i postępuj zgodnie z info na ekranie.',
  thanks: 'Dziękuję!',
};

const Cards = (props: Props) => {
  const { t } = useTranslation();
  const { code, multiply, name } = props;
  const card = (index: number) => (
    <View style={styles.card} key={index}>
      <Image style={styles.border} src={borderImage} />
      <View style={styles.cardWrapper}>
        <View style={styles.leftColumn}>
          <Text style={styles.textHeader}>{backendText.header}
            {name && (
              <>
                <Text>, {t('im')}</Text>
                <Text style={styles.textName}>{name}</Text>
              </>
            )}
          </Text>
          <Text style={styles.textMain}>{backendText.main}</Text>
          <Text style={styles.textInfo}>{backendText.info}</Text>
          <Text>{backendText.thanks}</Text>
        </View>
        <View style={styles.rightColumn}>
          <View style={styles.codeSection}>
            <Image style={styles.qrCode} src={code} />
          </View>
          <View style={styles.logoSection}>
            <Text style={styles.logoText}>Powered by</Text>
            <Image style={styles.logo} src={logoImage} />
          </View>
        </View>
      </View>
    </View>
  );

  const cards = [];
  for (let i = 0; i < multiply; i++) {
    cards.push(card(i));
  }

  return cards;
};

type Props = {
  code: string,
  name: string,
  multiply: number,
};

const PrintFullPage = (props: Props) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {Cards(props)}
      </Page>
    </Document>
  );
};

export default PrintFullPage;
