import { useState, useEffect, useContext } from 'react';
import UserConsumer from 'context/user/User';

type ContextProps = {
  currentUser: {
    userId: string,
    isLoggedIn: boolean,
    iOS: boolean,
  },
};

// Vibrating only works on Android operating system
export const useVibrating = (thankYouPage: boolean) => {
  const userConsumer = useContext<Partial<ContextProps>>(UserConsumer);
  const { currentUser: { iOS } }: any = userConsumer;

  const [options, setOptions] = useState({ active: false, allowVibrate: false });

  useEffect(() => {
    if (!iOS && thankYouPage) {
      setOptions({ ...options, allowVibrate: true });
      window.navigator.vibrate([200, 100, 200]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thankYouPage]);

  return options;
};
