import React, { useEffect, useState } from 'react';
import styles from './ApplePay.module.scss';
import ButtonBase from '@material-ui/core/ButtonBase';
import { BackApp } from 'libs/App';

// Initialize backend methods
const backLib = BackApp();

// Props types
type Props = {
  method: string,
  select: (value: any) => void,
  setApplepayToken: (value: any) => void,
  setApplepayClicked: (value: any) => void,
  applepayClicked: boolean,
  totalPrice: string,
};

const getOnValidateMerchantHandler = (session: any) => (event: any) => {
  backLib.paymentAppleRequestPaymentSession(event.validationURL, window.location.hostname)
      .then((response: any) => {
          const sessionObject = response.data;
          console.log('Going to complete merchant validation with session', sessionObject);
          session.completeMerchantValidation(sessionObject);
      })
      .catch((error: any) => {
          console.error("Error requesting Payment Session", error);
          session.abort();
      });
};

const getOnCancel = () => console.log('Payment cancelled.');

const ApplePayButton = (props: Props) => {
  // Only for styling purposes (active border)
  const selectMethod = () => props.select('applepay');

  const getOnPaymentAuthorized = (session: any) => {
    return (event: any) => {
        console.log('Payment authorized', event);
        session.completePayment((window as any).ApplePaySession.STATUS_SUCCESS);
        props.setApplepayToken(event.payment.token.paymentData);
    };
  };

  const handlePaymentButtonClicked = () => {
    const request = {
        countryCode: 'PL',
        currencyCode: 'PLN',
        supportedNetworks: ['visa', 'masterCard'],
        merchantCapabilities: ['supports3DS'],
        total: { label: 'Your Merchant Name', amount: props.totalPrice },
    };

    const session = new (window as any).ApplePaySession(3, request);
    session.onvalidatemerchant = getOnValidateMerchantHandler(session);
    session.onpaymentauthorized = getOnPaymentAuthorized(session);
    session.oncancel = getOnCancel();

    session.begin();
  };

  useEffect(() => {
    if (props.applepayClicked) {
      handlePaymentButtonClicked();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.applepayClicked]);

  return  (
    <ButtonBase onClick={selectMethod}>
      <div className={`${styles.ApplePay_paymentItem} ${props.method === 'applepay' && styles.ApplePay_paymentItem__selected}`}>
        <img src={require(`assets/applepay.svg`)} width="74" alt="" />
      </div>
    </ButtonBase>
  );
};

export default ApplePayButton;
