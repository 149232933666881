/* eslint-disable */
/* tslint:disable */

import * as firebase from 'firebase';

export function BackApp(opts) {
  if (!(this instanceof BackApp)) { return new BackApp(opts); }

  for (var key in opts) {
    if (opts.hasOwnProperty(key)) { this.opts[key] = opts[key]; }
  }

  this.instance = Math.round(Math.random() * 2000);
  //this.initialize();
  console.log('Version: ' + this.version);
  return this;
}

BackApp.prototype = {
  version: '1.3.3',
  updated: '2019/10/18',
  opts: {
  },

  model: {
    user: {},
    userData: {},// additional data of the logged in user
    empire: {}, //currently logged in user empire
    thirdparty: {},
    maps: [],
    scenarios: [],
    scenarioHolder: {},  // stores scenario object during create and scenario configuration    
    isDeploymentConfigured: false,
    isVictoryConditionsConfigured: false,
    userMarkedAsInactive: false
  },
  view: {
    tagsInputs: {}
  },

  timeout: 8000,
  //inactivityTimeMs: 5*60000, // 15 minutes
  inactivityTimeMs: 60000, // 60 seconds


  initialized: function () {
    var that = this;

    return firebase.auth().getRedirectResult()
      .then(function (result) {
        if (result.credential) {
          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          var token = result.credential.accessToken;
          that.model.thirdparty.apiToken = token;
          // [START_EXCLUDE]
          console.log('Result credential', result, result.user);
          // window.location.replace("/");
        } else {
          console.log('Result', result);
        }



        // The signed-in user info.
        var user = result.user;
      })
      .then(() => {
        return new Promise(function (resolve, reject) {
          firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
              that.model.user = user;
              console.log('Zalogowany', user, user.providerData);
              // if (sessionStorage.getItem('redirectURL')) {
              //   var redirectURL = sessionStorage.getItem('redirectURL');
              //   sessionStorage.removeItem('redirectURL');
              //   window.location.replace(redirectURL);
              // }
            } else {
              that.model.user = {};
              console.log('Wylogowany', user);
              // if (window.location.href.indexOf("/login.html") == -1 && window.location.href.indexOf("/fb-login.html") == -1 && window.location.href.indexOf("/give.html") == -1) {
              //   console.log(window.location.href);
              //   var continueUrl = window.location.href;
              //   sessionStorage.setItem('redirectURL', continueUrl);
              //   window.location.replace("/login.html");
              // }

            }
            resolve(that.model.user);
          });
        });

      });
  },

  /**
   * Loads wallet for currently logged in user
   * @module paytip-core
   * @returns  Promise that resolves to single item array with wallet data at result[0]
   */
  loadUserWallet: function (uid) {
    var that = this;

    return new Promise(function (resolve, reject) {
      that.loadUserEntitySingle(uid, 'wallet/users').then(function (resultArray) {
        resolve(resultArray);
      }).catch(function (error) {
        reject(error);
      })
    });
    // return new Promise(this.loadUserEntitySingle(this.model.user.uid, 'wallet/users'));
  },

  /**
   * Retrieves configuration
   * @param {*} configKey key of the configuration to retrieve   
   * @returns  Promise that resolves to single item array with value of the configuration key at result[0]
   * @module paytip-core
   */
  loadConfig: function (configKey) {
    var resultArray = this.loadUserEntitySingle(configKey, 'paytip/config');
    return resultArray;
  },

  /**
   * 
   * @param {*} handler 
   * @module paytip-core
   */
  observeReceiveables: function (handler) {
    this.observeUserEntityWithHandler('userReceiveables', 'st', handler);
  },

  /**
   * Observes user shared wallets
   * @param {*} handler 
   * @module paytip-core
   */
  observeUserShared: function (handler) {
    this.observeUserEntityWithHandler('userShared', null, handler);
  },

  /**
   * Returns list of shared elements that current user is participant of.
   * The list is sorted alphabetically using shared label (l) field.
   * @param {*} handler 
   * @module paytip-core
   */
  observeShared: function (handler) {
    this.observeUserEntityWithHandler('userShared', 'l', handler);
  },


  /**
   * Generates QRcode png image in base64 encoded format
   * @param {*} hostname for the action that will be linked to the qrcode
   * @param {*} amount base amount to be used for tip
   * @param {*} name name of the person that will receive the tip, used for presentation purposes
   * @param {*} isAmountFixed when true then the value given in amount will be presented for the user, otherwise 3 values on top of this value will be presented
   * @returns base 64 encoded image that can be used as img src attribute
   * @module paytip-qrcode
   */
  generateQRCode: function (hostname, amount, name, isAmountFixed) {
    var that = this;
    return new Promise(function (resolve, reject) {
      var callable = firebase.functions().httpsCallable('qrcodeGenerate');
      var dto = {
        hostname: hostname || 'https://www.execon.pl',
        amount: amount || 10,
        name: name || 'Paytip',
        amountFixed: isAmountFixed || false
      };

      callable(dto)
        .then(response => {
          // data {u: _url_, m: _link_}
          resolve(response.data);
        })
        .catch(error => {
          console.error(error);
          reject(error);
        })
    });
  },

  checkSharedOwnership: function (sharedId, userId) {
    var that = this;
    return new Promise(function (resolve, reject) {

      var callable = firebase.functions().httpsCallable('sharedIsOwner');

      var dto = {
        s: sharedId
      };

      var request = {
        dto: dto,
        userId: userId
      };

      callable(request).then(function (result) {
        console.log('Shared isOwner', result.data);
        resolve(result.data);
      })
        .catch(error => {
          reject(error);
        });
    });
  },

  joinShared: function (sharedId, userId) {
    var that = this;
    return new Promise(function (resolve, reject) {

      var callable = firebase.functions().httpsCallable('sharedJoin');

      var dto = {
        s: sharedId
      };

      var request = {
        dto: dto,
        userId: userId
      };

      callable(request).then(function (result) {
        console.log('Shared joined', result);
        resolve(result);
      })
        .catch(error => {
          console.log('Error joining shared', error);
          reject(error);
        });
    });
  },

  loadActiveSharedParticipants: function(){
    return this._callAPI('sharedParticipants')
    .then(participantsArray=>{
      console.log('Received participants', participantsArray);
      return participantsArray.data;
    })
    .catch(error=>{
      console.log('Error receiving participants', error);
      return [];
    })
  },

  _callAPI: function(operation, dto, userId){
    var that = this;
    return new Promise(function (resolve, reject) {

      var callable = firebase.functions().httpsCallable(operation);
      
      var request = {
        dto: dto,
        userId: userId
      };

      callable(request).then(function (result) {
        console.log('API call success', result);
        resolve(result);
      })
        .catch(error => {
          console.log('API call error', error);
          reject(error);
        });
    });
  },

  /**
   * Creates new shared element with given label
   * @param {*} label human readable name of the shared element, required
   * @param {*} userId owner user of the shared, when not provided then currently logged in user will be used, optional
   */
  registerShared: function (label, userId) {
    var that = this;
    return new Promise(function (resolve, reject) {

      var callable = firebase.functions().httpsCallable('sharedCreate');

      var dto = {
        l: label
      };

      var request = {
        dto: dto,
        userId: userId
      };

      callable(request).then(function (result) {
        console.log('Shared created', result);
        resolve(result);
      })
        .catch(error => {
          console.log('Error creating shared', error);
          reject(error);
        });
    });
  },

  /**
   * Registers payment for currently logged in user
   * @param {*} amount in currency units (groszy)
   * @param {*} code payment authorisation code
   * @param {*} userId when provided payment will be for user with given id, when not provided then payment is done for the currently logged in user
   * @param {*} provider B for BLIK, G for Google Pay, A for Apple Pay
   * @module paytip-core
   */
  registerPayment: function (amount, code, userId, provider) {
    var that = this;
    return new Promise(function (resolve, reject) {

      var callable = firebase.functions().httpsCallable('walletAcceptTip');

      var userReceivableDTO = {
        a: amount,
        c: code,
        p: provider
      };

      var walletAcceptTipDTO = {
        userReceivable: userReceivableDTO,
        userId: userId
      };

      callable(walletAcceptTipDTO).then(function (result) {
        console.log('Received in', result);
        resolve(result);
      })
        .catch(error => {
          console.log('Error registering tip', error);
          reject(error);
        });
    });
  },

  /**
   * Returns MerchantSession which is an opaque message session object, received from the Apple Pay server
   * @param {*} url url retrieved from onvalidatemerchant function
   * @param {*} domain domain to which merchant is authorized
   */
  paymentAppleRequestPaymentSession: function (url, domain) {
    var that = this;
    return new Promise(function (resolve, reject) {

      var callable = firebase.functions().httpsCallable('paymentAppleRequestPaymentSession');
      var paymentSessionRequest = {
        v: url,
        d: domain
      };
      var paymentSessionRequestDTO = {
        request: paymentSessionRequest,
        userId: null
      };
      console.log('Going to request Apple Payment Session', paymentSessionRequestDTO);
      callable(paymentSessionRequestDTO).then(function (result) {
        console.log('Received Apple Payment Session', result);
        resolve(result);
      })
        .catch(error => {
          console.error('Error requesting apple pay payment session', error);
          reject(error);
        });
    });
  },

  /**
 * Creates withdrawal request. By default uses user's wallet, when shared id is provided then withdrawal from shared wallet is issued.
 * @param {*} amount in currency units (groszy)   
 * @param {*} sharedId optional, when provided the withdrawal will use currently user's active shared wallet (proven that the user has priviledge to do so). Otherwise, user's wallet will be used for withdrawal.
 * @module paytip-core
 */
  registerWithdrawal: function (amount, sharedId) {
    var that = this;

    return new Promise(function (resolve, reject) {
      var userWithdrawalDTO = {
        a: Math.floor(amount),
        s: sharedId
      };
      var walletWithdrawCallable = firebase.functions().httpsCallable('walletWithdraw');
      walletWithdrawCallable(userWithdrawalDTO).then(function (result) {
        resolve(result);
      }).catch(err => {
        reject(err);
      });

    });


  },

  /**
   * Checks if the logged in user has provided financial data
   * @module paytip-core
   * @returns Promise that resolves true when the data is set, false otherwise
   */
  isUserFinancialDataSet: function () {
    var that = this;

    return new Promise(function (resolve, reject) {
      var result = false;

      that.backendLoadUser(that.model.user.uid).then((userData) => {
        result = userData.bn ? true : false;
        result = userData.dn ? result && true : false;
        result = userData.s ? result && true : false;
        result = userData.sno ? result && true : false;
        result = userData.z ? result && true : false;
        result = userData.c ? result && true : false;

        resolve(result);
      })
    });
  },

  updateUserDataField: function (fieldName, newValue) {
    var that = this;
    var transactionDateTs = Date.now();
    var change = {};
    change[fieldName] = newValue;
    return that.backendUpdateUserEntity('userData', that.model.user.uid, change);
  },

  /**
 * 
 * @param {*} amount in currency units (groszy)
 * @param {*} code payment authorisation code
 * @module paytip-core
 * @returns Promise being the result of the financial data update
 */
  userUpdateFinancialData: function (bankNo, displayName, street, streetNo, zipCode, city) {
    var that = this;
    var transactionDateTs = Date.now();
    return that.backendUpdateUserEntity('userData', that.model.user.uid, {
      dn: displayName,
      bn: bankNo,
      s: street,
      sno: streetNo,
      z: zipCode,
      c: city,
      mt: transactionDateTs
    });
  },

  /**
   * 
   * @param {*} email 
   * @param {*} password 
   * @param {*} name 
   * @param {*} surname 
   * @param {*} id1 
   * @param {*} id2 
   * @param {*} street 
   * @param {*} streetNo 
   * @param {*} zipCode 
   * @param {*} city 
   * @module paytip-core
   */
  // userJoin: function (email, password, name, surname, id1, id2, street, streetNo, zipCode, city) {
  userJoin: function (email, password) {
    // var that = this;

    return new Promise(function (resolve, reject) {
      firebase.auth().createUserWithEmailAndPassword(email, password).then(function (user) {
        resolve(user);
        // window.location.replace("/");
      }).catch(function (error) {
        // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        reject(error);
      });
    });
  },

  /**
   * 
   * @param {*} login 
   * @param {*} password 
   * @module paytip-core
   * @returns Promise
   */
  userSignIn: function (login, password) {
    var that = this;
    return new Promise(function (resolve, reject) {
      firebase.auth().signInWithEmailAndPassword(login, password).then(function (user) {
        resolve(user);
        // window.location.replace("/");
      }).catch(function (error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        reject(error);
      });
    });

  },

  userSignInWithFacebook: function () {
    var that = this;

    var provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope('email');
    firebase.auth().signInWithRedirect(provider);
  },

  /**
   * @module paytip-core
   */
  userSignOut: function (e, that) {
    // var user = that.backendGetLoggedUser();

    return new Promise(function (resolve, reject) {
      firebase.auth().signOut().then(function () {
        // window.location.replace("logout");
        console.log('User logged out');
        resolve();
      }).catch(function (error) {
        // window.location.replace("logout");
        console.log('Can\'t logout user:', error);
        reject();
      });
    });
  },



  // showNotificationSuccess: function () {
  //   d3.select('.notification.operation-success').classed('is-hidden', null);

  //   setTimeout(function () {
  //     d3.select('.notification.operation-success').classed('is-hidden', true);
  //   }, this.timeout);
  // },

  // showNotificationError: function () {
  //   d3.select('.notification.operation-failure').classed('is-hidden', null);

  //   setTimeout(function () {
  //     d3.select('.notification.operation-failure').classed('is-hidden', true);
  //   }, this.timeout);
  // },




  validateEmail: function (email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },

  backendGetLoggedUser: function () {
    var user = firebase.auth().currentUser;
    // todo add loading userExtension
    return user;
  },



  /**
   * Saves user entity into database.
   * Examples:   
   * A. 'malina','uid1',entity with uid
   * the entity will be saved into /malina/uid1/uid
   * B. 'malina','uid1',entity without uid
   * the entity will be saved directly into /malina/uid1   
   * @param {string} entityName - path to the user entity (excluding userid and entity id) , ie. 'userEmpire'
   * @param {string} userId - id of the user for which to add entity
   * @param {object} entity - entity object, if the object has uid property then the entity will be added to the tree with uid
   */
  backendSaveUserEntity: function (entityName, userId, entity) {
    var entityPath;

    if (entity.uid)
      entityPath = '/' + entityName + '/' + userId + '/' + entity.uid;
    else
      entityPath = '/' + entityName + '/' + userId;
    return new Promise(function (resolve, reject) {
      firebase.database().ref(entityPath).set(entity, function (error) {
        resolve();
      });
    });

  },

  backendUpdateUserEntity: function (entityName, userId, entity) {
    var entityPath;

    if (entity.uid)
      entityPath = '/' + entityName + '/' + userId + '/' + entity.uid;
    else
      entityPath = '/' + entityName + '/' + userId;
    return new Promise(function (resolve, reject) {
      firebase.database().ref(entityPath).update(entity, function (error) {
        if (!error) {
          resolve();
        } else {
          reject();
        }
      });
    });

  },

  backendGenerateUUID: function () {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  },

  /**
   * Loads user entity that is expected to store a list of objects
   * @param {*} entityName 
   * @param {*} orderByField 
   */
  loadUserEntity: function (entityName, orderByField) {
    var resultArray = [];
    var that = this;
    var orderBy = orderByField || 'modifiedSort';

    return new Promise(function (resolve, reject) {
      var entityRef = firebase.database().ref('/' + entityName + '/' + that.model.user.uid).orderByChild(orderBy);

      entityRef.on('value', function (snapshot) {
        if (snapshot.val()) {
          snapshot.forEach(function (child) {
            resultArray.push(child.val());
          });
        } else { }
        return resolve(resultArray);
      });
    });
  },

  /**
   * Loads user entity that is expected to be a single object
   * @param {string} uid - uid of the user for which load the entity
   * @param {*} entityName 
   * @param {*} orderByField 
   * 
   * @returns single item array with entity data result[0]
   */
  loadUserEntitySingle: function (uid, entityName, orderByField) {
    var resultArray = [];
    var that = this;
    var orderBy = orderByField || 'modifiedSort';

    return new Promise(function (resolve, reject) {
      var entityRef = firebase.database().ref('/' + entityName + '/' + uid).orderByChild(orderBy);

      entityRef.on('value', function (snapshot) {
        if (snapshot.val()) {
          resultArray.push(snapshot.val());
        } else { }

        return resolve(resultArray);
      });
    });
  },


  observeUserEntity: function (entityName, orderByField, targetModelFieldName) {

    var that = this;
    var orderBy = orderByField || 'modifiedSort';

    var entityRef = firebase.database().ref('/' + entityName + '/' + that.model.user.uid).orderByChild(orderBy);

    entityRef.on('value', function (snapshot) {
      var resultArray = [];
      if (snapshot.val()) {
        snapshot.forEach(function (child) {
          resultArray.push(child.val());
        });
      } else { }
      console.log(resultArray);
      that.model[targetModelFieldName] = resultArray;
    });
  },



  observeEntityWithHandler: function (entityName, entityPath, orderByField, handler) {
    var that = this;
    var orderBy = orderByField;

    var entityRef = null;

    if (orderBy)
      entityRef = firebase.database().ref(entityPath).orderByChild(orderBy);
    else
      entityRef = firebase.database().ref(entityPath);

    entityRef.on('value', function (snapshot) {
      var resultArray = [];
      if (snapshot.val()) {
        snapshot.forEach(function (child) {
          resultArray.push({
            id: child.key,
            val: child.val()
          });
        });
      } else { }

      handler.hFunction.apply(handler.hObject, [entityName, resultArray]);
    });
  },

  observeUserEntityWithHandler: function (entityName, orderByField, handler) {
    var that = this;
    this.observeEntityWithHandler(entityName, '/' + entityName + '/' + that.model.user.uid, orderByField, handler);
  },

  /**
   * Loads user entity from data store by id
   * @param {*} uid 
   * @returns userData object
   */
  backendLoadUser: function (uid) {
    var that = this;

    return new Promise(function (resolve, reject) {
      that.loadUserEntitySingle(uid, 'userData').then(function (resultArray) {
        resolve(resultArray[0]);
      }).catch(function (error) {
        reject();
      })
    });
  },
  /**
   * Loads user empire entity from data store by id
   * @param {*} uid 
   * @returns userEmpire object
   */
  backendLoadUserEmpire: function (uid) {
    var that = this;

    return new Promise(function (resolve, reject) {
      that.loadUserEntitySingle(uid, 'userEmpire').then(function (resultArray) {
        resolve(resultArray[0]);
      }).catch(function (error) {
        reject();
      })
    });
  }
};