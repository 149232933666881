import Intro from 'views/intro/Intro';
import Login from 'views/login/Login';
import Logout from 'views/logout/Logout';
import ResetPassword from 'views/reset_password/Reset_password';
import ResetPasswordFinish from 'views/reset_password_finish/Reset_password_finish';
import SignUp from 'views/sign_up/Sign_up';
import SignUpFinish from 'views/sign_up_finish/Sign_up_finish';
import NewTip from 'views/new_tip/New_tip';
import Give from 'views/give/Give';
import About from 'views/about/About';
import TipCode from 'views/tip_code/TipCode';
import TipCodeGive from 'views/tip_code_give/TipCodeGive';
import Tips from 'views/tips/Tips';
import TipBox from 'views/tipBox/TipBox';
import Balance from 'views/balance/Balance';
import Profile from 'views/profile/Profile';
import TipBoxInvite from 'views/tip_box_invite/TipBoxInvite';

// Routes object.
// Items with menuOrder 0 don't appear in menu

// Role:
//        1 - Allow only for not logged in
//        2 - Allow only for logged in
//        3 - Allow all users

export const Routes = [
  {
    path: '/',
    menuOrder: 0,
    exact: true,
    component: Intro,
    translation: '',
    role: 3,
  },
  {
    path: '/login',
    menuOrder: 0,
    exact: true,
    component: Login,
    translation: '',
    role: 1,
  },
  {
    path: '/logout',
    menuOrder: 99,
    exact: true,
    component: Logout,
    translation: 'logout',
    role: 3,
  },
  {
    path: '/reset-password',
    menuOrder: 0,
    exact: true,
    component: ResetPassword,
    translation: '',
    role: 1,
  },
  {
    path: '/reset-password-finish',
    menuOrder: 0,
    exact: true,
    component: ResetPasswordFinish,
    translation: '',
    role: 1,
  },
  {
    path: '/sign-up',
    menuOrder: 0,
    exact: true,
    component: SignUp,
    translation: '',
    role: 1,
  },
  {
    path: '/sign-up/:terms',
    menuOrder: 0,
    exact: true,
    component: SignUp,
    translation: '',
    role: 1,
  },
  {
    path: '/sign-up-finish',
    menuOrder: 0,
    exact: true,
    component: SignUpFinish,
    translation: '',
    role: 1,
  },
  {
    path: '/new-tip',
    menuOrder: 1,
    exact: true,
    component: NewTip,
    translation: 'newTip',
    role: 2,
  },
  {
    path: '/tips',
    menuOrder: 2,
    exact: true,
    component: Tips,
    translation: 'tips_history',
    role: 2,
  },
  {
    path: '/balance',
    menuOrder: 3,
    exact: true,
    component: Balance,
    translation: 'my_balance',
    role: 2,
  },
  {
    path: '/tipcode',
    menuOrder: 4,
    exact: true,
    component: TipCode,
    translation: 'tipCode',
    role: 3,
  },
  {
    path: '/tipbox',
    menuOrder: 5,
    exact: true,
    component: TipBox,
    translation: 'tipBox',
    role: 2,
  },
  {
    path: '/tipbox-invite',
    menuOrder: 0,
    exact: true,
    component: TipBoxInvite,
    translation: '',
    role: 2,
  },
  {
    path: '/tipcode-give',
    menuOrder: 0,
    exact: true,
    component: TipCodeGive,
    translation: '',
    role: 3,
  },
  {
    path: '/profile',
    menuOrder: 6,
    exact: true,
    component: Profile,
    translation: 'profile',
    role: 2,
  },
  {
    path: '/about',
    menuOrder: 7,
    exact: true,
    component: About,
    translation: 'about_payTip',
    role: 2,
  },
  {
    path: '/about/:terms',
    menuOrder: 0,
    exact: true,
    component: About,
    translation: 'about_payTip',
    role: 2,
  },
  {
    path: '/give',
    menuOrder: 0,
    exact: true,
    component: Give,
    translation: 'give_tip',
    role: 3,
  },
];
