import React, { useState, useEffect, useContext, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import UserConsumer from 'context/user/User';
import { useTranslation } from 'react-i18next';
import {
  fullDayFormat,
  rangeDates,
  countDailyTip,
  countHeightOfRangeAmount,
  priceDecimalString,
  filterHistoryData,
} from 'utils/calculates';
import { dayFormat } from 'utils/validators';

import styles from './Tips.module.scss';
import firebase from 'firebase';
import moment from 'moment';
import { ButtonBase } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { ButtonCircleStyle } from 'components/override_styles/ButtonCircle';
import ArrowIcon from 'assets/arrow_transfer.svg';
import SpinnerSmall from 'components/layout/spinner/SpinnerSmall';

const NEW_TIP = 'T';
const SHARED_TIP = 'S';
const INT_TRANSFER = 'IT';
const WITHDRAW_TIPBOX = 'SW';
const KEY = 0;
const VALUE = 1;
const OPTION = ['day', 'week', 'month'];

type ContextProps = {
  currentUser: {
    userId: string,
    as: string,
    tipBoxName: string,
    singleModule: boolean,
  },
};

// Overridden material styles
const CssButtonCircle = ButtonCircleStyle(Fab);

const Tips = (props: RouteComponentProps) => {
  const userConsumer = useContext<Partial<ContextProps>>(UserConsumer);
  const { currentUser: { userId, as, singleModule, tipBoxName } }: any = userConsumer;
  const { t } = useTranslation();

  const today = moment().format(dayFormat);
  const [history, updateHistory] = useState([]);
  const [cards, setCards] = useState({
    day: '0,00',
    week: '0,00',
    month: '0,00',
  });
  const [rangeHistoryData, setRangeHistoryData] = useState({
    day: [],
    week: [],
    month: [],
  });
  const [currentType, setCurrentType] = useState('day');
  const [listData, setListData] = useState({});
  const [responseReady, setResponseReady] = useState(false);

  // Reference to DOM object
  const btnRef = useRef<HTMLDivElement>();

  const getHistoryData = () => {
    firebase.database().ref(`/userReceiveables/${userId}`).orderByChild('st').on('value', (snapshot: any) => {
      const resultArray: object[] = [];
      if (snapshot.val()) {
        snapshot.forEach((child: any) => {
          resultArray.push(child.val());
        });
      }
      setResponseReady(true);

      if (resultArray.length) {
        // Add new field 'day' in data history list and convert 'dateTime' to full date
        const convertedDate = resultArray.map((el: any) => ({
          ...el,
          ct: fullDayFormat(el.ct),
          mt: fullDayFormat(el.ct),
          day: moment(el.ct).format(dayFormat),
        }));

        // Group data by the same day
        const result = convertedDate.reduce((acc: any, item: any) => {
          acc[item.day] = acc[item.day] || [];
          acc[item.day].push(item);
          return acc;
        }, []);

        // @ts-ignore
        const weekDayStart = moment(today).startOf('isoweek').format(dayFormat);
        const monthDayStart = moment(today).startOf('month').format(dayFormat);

        // Filter result data by type mode (switch button) - single or sloik
        let filteredResults;
        let filteredTypeTransaction;

        if (!singleModule) {
          filteredResults = filterHistoryData(result, 'activeSloik', as);
          filteredTypeTransaction = [SHARED_TIP];
        } else {
          filteredResults = filterHistoryData(result, 'myTip', as);
          filteredTypeTransaction = [NEW_TIP, INT_TRANSFER];
        }

        // Set history list
        // @ts-ignore
        updateHistory(filteredResults);

        // Value displaying on cards depends on one of types 'day, week, currentMonth'
        const daiylyPrice = countDailyTip(filteredResults, today, filteredTypeTransaction);
        const weeklyPrice = countHeightOfRangeAmount(rangeDates(weekDayStart, today), filteredResults, filteredTypeTransaction);
        const monthPrice = countHeightOfRangeAmount(rangeDates(monthDayStart, today), filteredResults, filteredTypeTransaction);

        setCards({
          day: daiylyPrice,
          week: weeklyPrice,
          month: monthPrice,
        });

        // @ts-ignore
        setRangeHistoryData({
          day: [today],
          week: rangeDates(weekDayStart, today),
          month: rangeDates(monthDayStart, today),
        });

        // Get reference to cards 'day' and init click on that
        if (btnRef.current && btnRef.current.previousSibling) {
          const weekCard: any = btnRef.current.previousSibling;

          if (weekCard) {
            weekCard.previousSibling.click();
          }
        }
      }
    });
  };

  useEffect(() => {
    getHistoryData();
    return () => {
      firebase.database().ref(`/userReceiveables/${userId}`).off();
    };
  }, [userConsumer.currentUser]);

  // When data are changed update history list depends on selected type
  useEffect(() => OPTION.forEach((type: string) => type === currentType && selectHistoryType(type)), [history]);

  // This type of transaction is shared
  const sharedTipOnActivatedSloik = (isCharged: string, opt1: any, opt2: any) => isCharged === SHARED_TIP ? opt1 : opt2;

  const selectHistoryType = (type: string) => {
    setCurrentType(type);
    // @ts-ignore
    const selected = rangeHistoryData[type];

    // @ts-ignore
    const rangeListToRender = Object.entries(history).filter((el) => selected.includes(el[KEY])).reduce((obj, item) => (obj[item[KEY]] = item[VALUE], obj) ,{});
    setListData(rangeListToRender);
  };

  const priceComma = (price: any) => price.replace('.', ',');

  const cardsDate = (data: any) => {
    if (data) {
      return Object.entries(data).map((el) => {
        return (
          // @ts-ignore
          <ButtonBase ref={btnRef} key={el[KEY]} onClick={() => selectHistoryType(el[KEY])}>
            <div className={`${styles.tips_periodItem} ${el[KEY] === currentType && styles.tips_periodItem__active}`}>
              <span className={styles.tips_periodItemTime}>
                {t(el[KEY])}
              </span>
              <span className={styles.tips_periodItemAmount}>
                {priceComma(el[VALUE])} zł
              </span>
            </div>
          </ButtonBase>
        );
      });
    }
    return null;
  };

    // Format day 'dd.mm.yyyy'
  const formatDay = (data: string) => data.split('-').reverse().join('.');

  const transactionName = (el: {t: undefined | string}, amountOut: boolean) => {
    switch (el.t) {
      case 'T':
        return amountOut ? t('toTipbox') : t('tip');
      case 'IT':
        return t('fromTipbox');
      case 'W':
        return t('payout');
      case 'S':
        return t('sharedTip');
      case 'SW':
        return t('withdrawTipbox');
      case 'V':
        return t('verificationTip');
      default:
        return;
    }
  }

  const singleDayHistory = (data: any) => {
    return (
      <div key={data[KEY]} className={styles.tips_dateList}>
        <p className={styles.tips_dateTitle}>{formatDay(data[KEY])}</p>
        <table className={styles.tips_dateTable}>
          <tbody>
            {
              data[VALUE].map((el: any, i: any) => {
                // singleModule is selected
                if (singleModule) {
                  const fromTipBOX = (`${el.a}`).includes('-');
                  const payoutMoney = transactionName(el, fromTipBOX) === 'Wypłata';
                  const outTransaction = fromTipBOX || payoutMoney;

                  return (
                    <tr key={i} className={styles.tips_dateTableRow} style={{ color: outTransaction ? '#AFAFAF' : ''}}>
                      <td className={styles.tips_hourCellSingle}>{el.ct.substr(11)}</td>
                      <td>{transactionName(el, outTransaction)}</td>
                      <td
                        className={styles.tips_dateTableValue}
                        style={{ color: outTransaction ? '#CDD8EF' : '#2B0096' }}
                      >
                        {outTransaction ? '-' : '+'} {priceDecimalString(el.a).replace('-', '')} zł
                      </td>
                    </tr>
                  );
                }

                // sloik is selected
                return (
                  <tr key={i} className={styles.tips_dateTableRow} style={{ color: sharedTipOnActivatedSloik(el.t, '#404040', '#AFAFAF') }}>
                    <td className={styles.tips_hourCellTipBox}>{el.ct.substr(11)}</td>
                    <td className={styles.tips_nameCell}>{el.l}</td>
                    <td
                      className={styles.tips_dateTableValue}
                      style={{ color: sharedTipOnActivatedSloik(el.t, '#2B0096', '#CDD8EF') }}
                    >
                      {sharedTipOnActivatedSloik(el.t, '+' , '-')} {priceDecimalString(el.a)} zł
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    );
  };

  const historyList = (historyData: any) => {
    if (historyData) {
      return Object.entries(historyData).map((day) => singleDayHistory(day));
    }
  };

  const redirectToMyBalance = () => props.history.push('/balance');

  return (
    <div className={`${styles.tips_wrapper}`}>

      <div className="container">
        <h1 className={`title ${styles.tips_title}`}>{t('history')}</h1>

        <p className={`${styles.tips_subTitle}`}>
          {singleModule ? t('my_tips') : <>{t('tipBox')}&nbsp;<strong>{tipBoxName}</strong></>}
        </p>
        {
          responseReady ? (
            <>
              <div className={styles.tips_periodWrapper}>
                {cardsDate(cards)}
              </div>
              {listData && historyList(listData)}
            </>
          ) : <SpinnerSmall />
        }

        <CssButtonCircle
          color="primary"
          aria-label="add"
          style={{ position: 'fixed', bottom: '3rem', right: '3rem' }}
          onClick={redirectToMyBalance}
        >
          <img src={ArrowIcon} width="40" alt=""/>
        </CssButtonCircle>
      </div>
    </div>
  );
};

export default Tips;
