import React, { memo } from 'react';
import styles from './InfoBox.module.scss';
import IconInfo from 'assets/icon_info.svg';

type Props = {
  text: string,
};

const InfoBox = (props: Props) => {
  const { text } = props;

  if (text) {
    return (
      <div className={styles.InfoBox_wrapper}>
        <img src={IconInfo} className={styles.InfoBox_img} width="15" alt=""/>
        <p className={styles.InfoBox_text}>{text}</p>
      </div>
    );
  }
  return null;
};

export default memo(InfoBox);
