import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import styles from './Intro.module.scss';

import { ReactComponent as Logo } from 'assets/pay-tip-logo-with-background.svg';

const Intro = () => {
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    // Redirect this page to the login view after 2s
    const redirectTimer = setTimeout(() => { setRedirect(true); }, 2000);

    return () => {
      clearTimeout(redirectTimer);
    };
  });

  return (
    <div className={styles.splash}>
      {redirect && (<Redirect to="/login" />)}
      <Logo className={styles.splash_jar} />
      <div className={styles.splash_gradientText}>{t('app_name')}</div>
      <p className={styles.splash_introText}>{t('intro_text')}</p>
    </div>
  );
};

export default Intro;
