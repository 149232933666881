import React from 'react';

import SpinnerLarge from 'components/layout/spinner/SpinnerLarge';

import styles from './Loading.module.scss';

const Loading = () => {
  return (
    <div className={styles.loading}>
      <SpinnerLarge />
    </div>
  );
};

export default Loading;
