export const en = {
  app_name: 'PayTip',
  intro_text: '[EN] Przyjmuj napiwki i spełniaj swoje marzenia!',
  login_title: 'Login',
  email: 'E-mail',
  password: 'Password',
  email_placeholder: 'Type e-mail',
  password_placeholder: 'Type password',
  login: 'Login',
  forgot_password: 'Don\'t remember password',
  wrong_data: 'Wrong data',
  login_facebook: 'Login via facebook',
  dont_have_account: '[EN] Nie masz jeszcze konta PayTip?',
  sign_up: 'Sign up',
  sign_in: 'Sign in',
  reset_password_title: 'Reset password',
  reset_password_help: '[EN] Podaj e-mail powiązany z Twoim kontem, a prześlemy tam link do zmiany hasła.',
  reset_password_info: '[EN] Link do zmiany hasła został wysłany.',
  reset_password: 'Reset password',
  sign_up_title: 'Sign Up',
  sign_up_info: '[EN] Link aktywacyjny został wysłany na podany adres e-mail.',
  create_account: 'Create account',
  hello: 'Hello',
  im: 'I\'m',
  terms_accept_text1: '[EN] Zapoznałem się z',
  terms_accept_text2: '[EN] oraz wyrażam zgodę na przetwarzanie moich danych.',
  terms_accept_link: '[EN] regulaminem',
  error_email: 'Wrong email address',
  error_password: 'Min characters length: 8',
  already_have_account: 'Already have account?',
  logged_out: 'Logged out',
  login_again: 'Login again',
  newTip: 'New tip',
  newTipBox: 'New TipBox',
  tip: 'Tip',
  invoice_amount: 'Invoice amount',
  received_tips: 'Received tips',
  tips_history: 'Tip history',
  history: 'History',
  my_balance: 'My balance',
  my_tips: 'My tips',
  day: 'Day',
  week: 'Week',
  month: 'Month',
  tipCode: 'TipCode',
  profile: 'Profile',
  editProfile: 'Edit Profile',
  about_payTip: 'About PayTip',
  logout: 'Logout',
  tip_height: 'Tip height',
  currency: 'gbp',
  paymentType: 'Payment',
  writeBlickCodeOrTikCode: 'Pass your BLIK code or use TipCode.',
  blikCode: 'Blik code',
  tipCodeNewTip: 'Tip code',
  blikCodePlaceholder: ' 6 digits',
  tipPlaceholder: 'Ex.: 29.00',
  pay: 'Pay',
  tipWentToYourAccount: 'Tip went to your account',
  errMessage: 'Ups, something went wrong. Please try again',
  errMessage_maxReceipt: '[EN] Maksymalna wysokość rachunku to',
  errMessage_minReceipt: '[EN] Minimalna wysokość rachunku to',
  errMessage_maxTip: '[EN] Maksymalna wysokość napiwku to',
  errMessage_minTip: '[EN] Minimalna wysokość napiwku to',
  choosePaymentType: 'Select payment type',
  giveTip: 'Give a tip',
  writeBlikCode: 'Pass BLIK code',
  thankYouFortTIP: 'Thank you for a tip',
  thankYou: 'Thank You',
  thankYouPaymentRegister: '[EN] Zlecenie zostało przyjęte do realizacji.',
  version: 'Version',
  whats_new_in_app: 'What\'s new in App',
  terms: 'Terms of use',
  terms_and_rules: '[EN] Zasady i regulamin PayTip.io',
  terms_text: `
    <p>
      Cupcake ipsum dolortermsamet marshmallow bear claw bear claw fruitcake. Chocolate bar bonbon icing ice cream sesame snaps sweet roll gummies apple pie. Icing icing jelly candy.
    </p>
    <p>
      Toffee croissant crtermsnt chocolate sugar plum fruitcake icing. Croissant jelly-o cake. Lemon drops croissant sugar plum oat cake pastry.
    </p>
    <p>
      Tootsie roll jelly terms jelly beans toffee cheesecake sugar plum cotton candy gummi bears dessert. Sweet roll tootsie roll chocolate dessert cupcake toffee.
    </p>
    <p>
      Croissant donut cantermsnes tootsie roll halvah. Lollipop chupa chups sugar plum. Pudding lollipop cotton candy icing lemon drops powder apple pie chocolate. Marzipan jelly brownie.
    </p>
    <p>
      Gummi bears marzipan cupcake jelly caramels pie halvah. Jujubes cake lemon drops pudding cake cupcake carrot cake cheesecake. Lollipop liquorice ice cream. Cookie macaroon tart tiramisu jujubes liquorice jelly-o. Chocolate bar chupa chups dragée.
    </p>
  `,
  about_whats_new: `
    <div>
      <p>- Google Pay przy TipKodzie</p>
      <p>- TipKod wraz z poprawkami na ekranie dawania napiwku</p>
      <p>- Autouzupełnienia nazwy na TipCode</p>
      <p>- Generowanie QRcodes oraz obsługa wpłat z QRcodes</p>
      <p>- Wpięcie abee analityki</p>
      <p>- Poprawka księgowania wpłaty na wallecie usera pomniejszonej o prowizję</p>
      <p>- poprawka na wyświetlanie poniedziałkowych transakcji zaliczanych do bieżącego tygodnia</p>
      <p>- odbieranie powiadomienia z BLIK o udanej transakcji</p>
      <p>- integracja płatności BLIK z testowym PayU.</p>
    </div>
    <div>
      <p>Po wykonaniu napiwku płatność można zobaczyć pod linkiem <a href="/balance">tutaj</a></p>
      <p>- wypłata środków wymaga podania danych w profilu</p>
      <p>- dla konta nie facebook możliwość resetu hasła</p>
    </div>
  `,
  tipCodeContent: '[EN] Jeżeli jesteś zadowolony z obsługi przyznaj mi napiwek za pomocą tego kodu QR. Zeskanuj kod i postępuj zgodnie z info na ekranie.',
  tipCodeContentThankyou: '[EN] Z góry dziękuję i polecam się na przyszłość!',
  new_update: 'New version is avaliable! Please restart App.',
  reload: 'Reload',
  tipCodeContentGive: '[EN] Jeżeli jesteś zadowolony z obsługi przyznaj mi napiwek. Z góry dziękuję i polecam się na przyszłość!',
  giveTipButton: '[EN] Give a tip',
  valueOfTipHeight: '[EN] Please fill in tip height',
  entry: '[EN] Dalej',
  save: 'Save',
  account_data: 'Account data',
  user_id: 'User ID',
  payback_data: 'Payback data',
  bank_account_number: 'Your bank account number',
  account_number: 'Account number',
  receiver_name: 'Name and surname',
  pesel: 'PESEL',
  name_and_surname: 'Name and surname',
  street: 'Street',
  building_number: 'Building number',
  zip_code: 'Zip code',
  city: 'City',
  wrong_account_number: 'Wrong account number',
  required: 'This field is required',
  individualMeasures: '[EN] Środki indywidualne',
  walletOwnerTip: '[EN] Aby zlecić wypłatę środków z TipBox musisz być jego leaderem.',
  walletMoreThanValue: '[EN] Aby zlecić wypłatę musisz posiadać minimum',
  walletFillUserData: '[EN] Aby zlecić wypłatę środków uzupełnij dane finansowe w profilu',
  walletSubmit: '[EN] Środki zostaną przelane na wskazany w danych profilowych rachunek bankowy.',
  walletSubmitTipBox: '[EN] Środki zostaną przekazane na konta indywidualne osób podłączonych do tego TipBoxa.',
  walletTransfer: '[EN] Przelej środki',
  payout: 'Payout',
  save_error: '[EN] Coś poszło nie tak. Dane nie zapisane.',
  save_success: '[EN] Dane zostały zapisane.',
  no_data: 'No Data',
  information: 'Information',
  noActiveTipbox: '[EN] Nie masz aktywnego TipBoxa. Aby aktywować wybierz z listy TipBoxów.',
  goFurther: '[EN] Przejdź do',
  tipBox: 'TipBox',
  tipBoxes: 'TipBoxes',
  invite: 'Invite',
  createNew: 'Create New',
  namePlaceholder: 'Name',
  newTipBoxName: '[EN] Nazwa TipBox musi zawierać co najmniej 3 znaki',
  create: 'Create',
  cumulateTips: '[EN] Teraz zbierasz napiwki do TipBoxa:',
  selectTipBox: '[EN] Wybierz TipBox do zaproszenia:',
  tipBoxInviteInfo: '[EN] Zeskanuj QR kod, żeby dołączyć do TipBoxa. Aby dołączyć odbiorca musi posiadać konto i aplikację PayTip.',
  empty: 'Empty',
  members: 'Members',
  leader: 'Leader',
  toTipbox: 'To TipBox',
  fromTipbox: 'From TipBox',
  sharedTip: 'Shared tip',
  withdrawTipbox: 'Withdraw tipbox',
  verificationTip: 'Verification',
  print_and_download_tipcodes: 'Download and print TipCodes',
  set: 'Set',
  set_info: '[EN] Dodaj TipKod do każdego zamówienia',
  badge: 'Badge',
  badge_info: '[EN] Informacja do zawieszenia w lokalu',
  do_you_like_it: '[EN] Podoba Ci się?',
  leave_tip: 'Leave tip',
  print_info_text: '[EN] Jeśli chcesz docenić nasz zespół zeskanuj QR kod i postępuj zgodnie z instrukcją.',
  scan_me: 'Scan me',
  inviting_tipBox: '[EN] Trwa dołączanie do TipBoxa...',
  congratulations: 'Congratulations',
  added_to_tipBox: '[EN] Dołączyłeś/aś do TipBoxa',
  please_choose: 'Please choose',
  where_collect: '[EN] Gdzie zbierasz napiwki',
  document_type: 'Document type',
  document_number: '[EN] Seria i numer dokumentu',
};
