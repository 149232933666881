import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';
import UserConsumer from 'context/user/User';
import { useTranslation } from 'react-i18next';
import styles from './Balance.module.scss';
import { BackApp } from 'libs/App';
import ThankYou from 'components/thankYou/ThankYou';
import { priceDecimalString } from 'utils/calculates';

import PriceStyling from 'components/priceStyling/PriceStyling';
import Loading from 'components/loading/Loading';
import SpinnerSmall from 'components/layout/spinner/SpinnerSmall';
import InfoBox from 'components/layout/infoBox/InfoBox';

import Button from '@material-ui/core/Button';
import { ButtonStyle } from 'components/override_styles/Button';

import Fab from '@material-ui/core/Fab';
import { ButtonCircleStyle } from 'components/override_styles/ButtonCircle';
import AddIcon from '@material-ui/icons/Add';
import firebase from 'firebase';

// Initialize firebase
const backLib = BackApp();

// Overridden material styles
const CssButton = ButtonStyle(Button);
const CssButtonCircle = ButtonCircleStyle(Fab);

type ContextProps = {
  currentUser: {
    userId: string,
    singleModule: string,
    as: string,
    tipBoxName: string,
  },
  configuration: {
    withdrawMinValue: number,
    withdrawTipboxMinValue: number
  },
};

const Balance = () => {
  const userConsumer = useContext<Partial<ContextProps>>(UserConsumer);
  const {
    currentUser: { userId, singleModule, as, tipBoxName },
    configuration: { withdrawMinValue, withdrawTipboxMinValue },
  }: any = userConsumer;

  // The minimum withdraw value from BE configuration
  const MIN_VALUE = withdrawMinValue;

  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [TYP, setTYP] = useState(false);

  const [owner, setOwner] = useState(false);
  const [availableFunds, setAvailableFunds] = useState();
  const [availableFundsSloik, setAvailableFundsSloik] = useState();
  const [financeData, setFinanceData] = useState(null);

  const getUserWallet = () => {
    firebase.database().ref(`/wallet/users/${userId}`).orderByChild('st').on('value', (snapshot: any) => {
      const resultObj: {} = {};
      if (snapshot.val()) {
        snapshot.forEach((child: any) => {
          Object.assign(resultObj, { [`${child.key}`]: child.val() });
        });
      }
      // @ts-ignore
      setAvailableFunds({ a: resultObj.a, b: resultObj.b });
    });
  };

  useEffect(() => {
    // Get user personal data
    backLib.backendLoadUser(userId).then((d) => setFinanceData(d)).catch(() => setError(true));
    getUserWallet();

    return () => {
      firebase.database().ref(`/wallet/users/${userId}`).off();
      firebase.database().ref(`/userShared/${userId}`).off();
    };
  }, []);

  useEffect(() => {
    // Check if current user is owner of current sloik
    backLib.checkSharedOwnership(as, userId).then((user: boolean) => setOwner(user));

    firebase.database().ref(`/userShared/${userId}`).orderByChild('st').on('value', (snapshot: any) => {
      const resultObj: {} = {};
      if (snapshot.val()) {
        snapshot.forEach((child: any) => {
          Object.assign(resultObj, { [`${child.key}`]: child.val() });
        });
      }
      // Store available sloik data
      Object.values(resultObj).map((el: any) => (el.s === as) && setAvailableFundsSloik(el));
    });
  }, [as]);

  const renderPriceData = (singleModuleType: string) => {
    // @ts-ignore
    const priceType = singleModuleType ? availableFunds.a : availableFundsSloik ? availableFundsSloik.a : null;
    const price = priceDecimalString(priceType);

    return (
      <PriceStyling
        tip={price}
        style={{
          color: singleModuleType ? '#2B0096' : '#AA4399',
          integer: 4,
          cents: 3.2,
          currency: 2.5,
        }}
      />
    );
  }

  // Request Withdrawal
  const walletTransfer = () => {
    const price = singleModule ? availableFunds.a : availableFundsSloik.a;
    let sharedId = (!singleModule && as) ? (as) : null;
    setLoading(true);

    backLib.registerWithdrawal(price, sharedId).then(() => {
      setLoading(false);
      setTYP(true);
    }).catch(() => {
      setLoading(false);
      setError(true);
    });
  };

  const payoutInforamtionData = (financeObject: object | null, singleModuleType: boolean) => {
    // @ts-ignore
    const priceType = singleModuleType ? availableFunds.a : availableFundsSloik && availableFundsSloik.a;
    const emptySloik = availableFundsSloik && availableFundsSloik.a <= withdrawTipboxMinValue;

    if (financeObject) {
      const accountNo = Object.keys(financeObject).includes('bn');

      if (singleModuleType) {
        if (priceType < MIN_VALUE) {
          if (!accountNo) {
            return (
              <>
                <InfoBox text={`${t('walletMoreThanValue')} ${MIN_VALUE / 100} zł.`} />
                <InfoBox text={t('walletFillUserData')} />
              </>
            );
          }
          if (accountNo) {
            return (
              <InfoBox text={`${t('walletMoreThanValue')} ${MIN_VALUE / 100} zł.`} />
            );
          }
        }

        if (priceType > MIN_VALUE) {
          if (!accountNo) {
            return (
              <>
                <InfoBox text={t('walletFillUserData')} />
                <Link to={'/profile'} className={styles.balance_editProfile}>{t('editProfile')}</Link>
              </>
            );
          }
          if (accountNo) {
            return (
              <>
                <CssButton
                  fullWidth
                  className="card__activeRotate"
                  size="large"
                  variant="outlined"
                  type="button"
                  onClick={walletTransfer}
                >
                  {t('walletTransfer')}
                </CssButton>
                <div className="mt-15">
                  <InfoBox text={t('walletSubmit')} />
                </div>
              </>
            );
          }
        }
      }

      if (owner) {
        return (
          <>
            <CssButton
              fullWidth
              className={`${emptySloik ? 'card__disabled' : 'card__activeRotate'}`}
              disabled={emptySloik}
              size="large"
              variant="outlined"
              type="button"
              onClick={walletTransfer}
            >
              {t('walletTransfer')}
            </CssButton>
            <div className="mt-15">
              <InfoBox text={t('walletSubmitTipBox')} />
            </div>
          </>
        );
      } else {
        return (
          <InfoBox text={t('walletOwnerTip')} />
        );
      }

    }
    return null;
  };

  return (
    <div className={`${styles.balance_wrapper}`}>

      {/* Render thankyYou page, when user registered withdraw */}
      <div className={`${styles.balance_thankYou}`} style={{ display: TYP ? 'grid' : 'none' }}>
        <ThankYou
          img="withdraw_balance.svg"
          background="jam_jar_bg.svg"
          text={t('thankYou')}
        >
          <p className={`${styles.balance_thankYouAddText}`}>{t('thankYouPaymentRegister')}</p>
          <Link to={'/new-tip'}>
            <CssButtonCircle
              color="primary"
              aria-label="add"
              style={{ position: 'absolute', bottom: '3rem', right: '3rem' }}
            >
              <AddIcon style={{ fontSize: '3.5rem' }} />
            </CssButtonCircle>
          </Link>
        </ThankYou>
      </div>

      <div className="container" style={{ display: !TYP ? 'grid' : 'none' }}>
        <h1 className={`title ${styles.balance_title}`}>{t('my_balance')}</h1>
        <p className={`${styles.balance_subTitle}`}>{singleModule ? t('individualMeasures') : <>{t('tipBox')}&nbsp;<strong>{tipBoxName}</strong></>}</p>

        {/* Customizing style for tip height, display content depends on current status */}
        <div className={`${styles.balance_tip} mt-15`}>
          { financeData && !error ? (
            <>
              {
                availableFunds && (
                  <>
                    {renderPriceData(singleModule)}
                    <div className={`${styles.balance_infobox}`}>
                      {payoutInforamtionData(financeData, singleModule)}
                    </div>
                  </>
                )
              }
            </>
            ) : error ? (<h1 className="title title__err">{t('errMessage')}</h1>) : <SpinnerSmall />
          }
        </div>

      </div>
      {loading && <Loading />}
    </div>
  );
};

export default Balance;
