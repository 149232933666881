import React, { useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import UserConsumer from 'context/user/User';
import firebase from 'firebase';
import styles from './TipBoxInvite.module.scss';
import { useTranslation } from 'react-i18next';
import SpinnerSmall from 'components/layout/spinner/SpinnerSmall';
import ThankYou from 'components/thankYou/ThankYou';
import { BackApp } from 'libs/App';

// Initialize backend methods
const backLib = BackApp();

type ContextProps = {
  currentUser: {
    userId: string,
  },
};

const TipBoxInvite = (props: RouteComponentProps) => {
  const { t } = useTranslation();

  const userConsumer = useContext<Partial<ContextProps>>(UserConsumer);
  const { currentUser: { userId } }: any = userConsumer;

  const [error, setError] = useState(false);
  const [thankYouPage, setThankYouPage] = useState(false);
  const [tipBoxName, setTipBoxName] = useState('');

  const parameters = props.location.search.replace('?', '').split('&');

  let parametersObject: {invitationCode: string, userId: string};

  // Assign to variables destructing URL's data
  parameters.forEach((item) => {
    const data = item.split('=');
    parametersObject = {...parametersObject, ...{[data[0]]: data[1]}};
  });

  // Get selected TipBox name
  const getTipBoxName = () => {
    firebase.database().ref(`/userShared/${userId}`).orderByChild('st').on('value', (snapshot: any) => {
      if (snapshot.val()) {
        snapshot.forEach((child: any) => {
          if (child.val().s === parametersObject.invitationCode) {
            setTipBoxName(child.val().l);
          }
        });
      }
    });
  };

  const redirectToTipBox = () => props.history.push('/tipbox');

  const joinTipBox = () => {
    backLib.joinShared(parametersObject.invitationCode, userId)
      .then(() => {
        getTipBoxName();
        setThankYouPage(true);
      })
      .catch((error) => {
        console.log('joinShared error:', error);
        setError(true);
      });
  };

  useEffect(() => {
    if (!parametersObject.hasOwnProperty('invitationCode')) {
      console.log('No parameter found!');
      setError(true);
    } else {
      joinTipBox();
    }
  }, []);

  return (
    <div className={styles.tipBoxInvite_wrapper}>
      <div className="container">
        { error ?
          (
            <h1 className="title title__err">{t('errMessage')}</h1>
          )
          :
          (
            !thankYouPage && !tipBoxName ? (
              <>
                <h2 className={styles.tipBoxInvite_header}>{t('inviting_tipBox')}</h2>
                <div className={styles.tipBoxInvite_spinner}>
                  <SpinnerSmall />
                </div>
              </>
            ) : (
              <ThankYou
                img="jam_jar.svg"
                background="jam_jar_bg.svg"
                text={`${t('congratulations')}!`}
                additionalText={`${t('added_to_tipBox')} "${tipBoxName}"`}
                clicked={redirectToTipBox}
                link={t('tipBox')}
              />
            )
          )
        }
      </div>
    </div>
  );
};

export default TipBoxInvite;
