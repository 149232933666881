import { withStyles } from '@material-ui/core/styles';
import { SelectProps } from '@material-ui/core/Select';

export const SelectStyle = (component: React.ComponentType<SelectProps>) => withStyles({
  root: {
    background: 'white',
    padding: '1rem',
    height: '2rem',
    lineHeight: '2rem',
    fontSize: '1.4rem',
    fontFamily: "'Montserrat', sans-serif",
  },
  select: {
    '&:focus': {
      background: 'white',
    },
  },
  icon: {
    top: 'inherit',
    fontSize: '2.5rem',
  },
})(component);
