import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import UserConsumer from 'context/user/User';
import TextField from '@material-ui/core/TextField';
import { InputFieldStyle } from 'components/override_styles/TextFieldWhite';
import Button from '@material-ui/core/Button';
import { ButtonStyle } from 'components/override_styles/ButtonWhite';
import InputMask from 'react-input-mask';
import { iban, validatePolish, passportNumberRegex } from 'utils/validators';
import Notification from 'components/notification/Notification';
import Select from '@material-ui/core/Select';
import { SelectStyle } from 'components/override_styles/SelectProfile';

import styles from './Profile.module.scss';
import { BackApp } from 'libs/App';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';

// Initialize backend methods
const backLib = BackApp();

// TODO: This is the better way for overriding styles in MUI
// we should refactor all code to this solution in the future
const useStyles = makeStyles(() => ({
  formControl: {
    margin: '0 0 2rem 0',
    '& fieldset': {
      border: '1px solid #CDD8EF',
      transition: 'none',
      borderRadius: '.6rem',
    },
  },
  selectLabel: {
    transform: 'none',
    color: '#404040',
    position: 'relative',
    fontSize: '1.4rem',
    fontFamily: 'Montserrat',
    marginBottom: '.8rem',
  },
}));

// Overridden material styles
const CssTextField = InputFieldStyle(TextField);
const CssButton = ButtonStyle(Button);
const CssSelect = SelectStyle(Select);

type ContextProps = {
  currentUser: {
    userId: string,
    email: string,
  },
};

type DocumentType = {
  name: string,
  code: string,
};

type ActivityType = DocumentType;

// TODO: Can by changed when BE was ready
const documentTypes = [
  {
    name: 'Dowód osobisty',
    code: 'I',
  },
  {
    name: 'Paszport',
    code: 'P',
  },
];

const activities = [
  {
    name: 'Restauracja',
    code: 'T13',
  },
  {
    name: 'Kawiarnia',
    code: 'T6',
  },
  {
    name: 'Dostawa jedzenia',
    code: 'T3',
  },
  {
    name: 'Salon urody',
    code: 'T14',
  },
  {
    name: 'Fryzjer',
    code: 'T4',
  },
  {
    name: 'Obsługa hotelowa',
    code: 'T11',
  },
  {
    name: 'Recepcja',
    code: 'T12',
  },
  {
    name: 'Szatnia',
    code: 'T15',
  },
  {
    name: 'Myjnia samochodowa',
    code: 'T10',
  },
  {
    name: 'Autodetailing',
    code: 'T2',
  },
  {
    name: 'Kurier',
    code: 'T9',
  },
  {
    name: 'Kasyno',
    code: 'T5',
  },
  {
    name: 'Kierowca Taxi',
    code: 'T8',
  },
  {
    name: 'Kierowca autobusu',
    code: 'T7',
  },
  {
    name: 'Artysta uliczny',
    code: 'T1',
  },
];

const alphabetic = (a: string, b: string) => a.localeCompare(b);

documentTypes.sort((a: DocumentType, b: DocumentType) => alphabetic(a.name, b.name));

activities.sort((a: ActivityType, b: ActivityType) => alphabetic(a.name, b.name));
activities.push({
  name: 'Inne',
  code: 'T0',
});

const Profile = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const time = 6000;
  const userConsumer = useContext<Partial<ContextProps>>(UserConsumer);
  const { currentUser: { userId, email } }: any = userConsumer;
  const initialUserData = {
    accountNumber: '',
    receiverName: '',
    pesel: '',
    street: '',
    buildingNumber: '',
    zipCode: '',
    city: '',
    documentType: '',
    documentNumber: '',
    activity: '',
  };
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [showFailNotification, setShowFailNotification] = useState(false);
  const [formData, setFormData] = useState(initialUserData);
  const [backendData, setBackendData] = useState(initialUserData);

  // Validation states
  const [accountNumberError, setAccountNumberError] = useState('');
  const [receiverNameError, setReceiverNameError] = useState('');
  const [peselError, setPeselError] = useState('');
  const [streetError, setStreetError] = useState('');
  const [buildingNumberError, setBuildingNumberError] = useState('');
  const [zipCodeError, setZipCodeError] = useState('');
  const [cityError, setCityError] = useState('');
  const [documentTypeError, setDocumentTypeError] = useState('');
  const [documentNumberError, setDocumentNumberError] = useState('');
  const [activityError, setActivityError] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let value = event.target.value;
    // Remove spaces and mask characters from data
    if (event.target.name === 'accountNumber' || event.target.name === 'zipCode' || event.target.name === 'pesel' || event.target.name === 'documentNumber') {
      value = value.replace(/\s|_/g, '');
    }

    if (event.target.name === 'documentNumber') {
      value = value.toUpperCase();
    }

    setFormData({...formData, [event.target.name]: value});
  };

  const handleSelectChange = (event: React.ChangeEvent<{value: unknown }>): void => {
    const value = event.target.value;
    setFormData({...formData, [(event.target as HTMLSelectElement).name]: value});
  };

  const isDocumentValid = (whatToValidate: string, documentNumber: string): boolean => {
    if (whatToValidate === 'I') {
      return validatePolish.identityCard(documentNumber);
    }
    if (whatToValidate === 'P') {
      return !!documentNumber.match(passportNumberRegex);
    }
    return true;
  };

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();

    // Validate inputs
    setAccountNumberError('');
    setReceiverNameError('');
    setZipCodeError('');
    setPeselError('');
    setStreetError('');
    setBuildingNumberError('');
    setCityError('');
    setDocumentNumberError('');
    setDocumentTypeError('');
    setActivityError('');

    let readyToSend = true;
    if (formData.accountNumber.length) {
      const plAccountNumber = `PL${formData.accountNumber}`;
      if (!iban.isValid(plAccountNumber)) {
        setAccountNumberError(t('wrong_account_number'));
        readyToSend = false;
      }
    } else {
      setAccountNumberError(t('required'));
      readyToSend = false;
    }

    if (!formData.receiverName.length) {
      setReceiverNameError(t('required'));
      readyToSend = false;
    }

    if (formData.pesel.length) {
      if (!validatePolish.pesel(formData.pesel)) {
        setPeselError(t('wrong_data'));
        readyToSend = false;
      }
    } else {
      setPeselError(t('required'));
      readyToSend = false;
    }

    if (!formData.documentType.length) {
      setDocumentTypeError(t('required'));
      readyToSend = false;
    }

    if (formData.documentNumber.length) {
      if (!isDocumentValid(formData.documentType, formData.documentNumber)) {
        setDocumentNumberError(t('wrong_data'));
        readyToSend = false;
      }
    } else {
      setDocumentNumberError(t('required'));
      readyToSend = false;
    }

    if (!formData.street.length) {
      setStreetError(t('required'));
      readyToSend = false;
    }

    if (!formData.buildingNumber.length) {
      setBuildingNumberError(t('required'));
      readyToSend = false;
    }

    if (formData.zipCode.length) {
      if (formData.zipCode.length !== 6) {
        setZipCodeError(t('wrong_data'));
        readyToSend = false;
      }
    } else {
      setZipCodeError(t('required'));
      readyToSend = false;
    }

    if (!formData.city.length) {
      setCityError(t('required'));
      readyToSend = false;
    }

    if (!formData.activity.length) {
      setActivityError(t('required'));
      readyToSend = false;
    }

    if (readyToSend) {
      console.log('No errors. Sending the data.');

      const mappedDataToSend = {
        dn: formData.receiverName,
        v: {
          i1: formData.pesel,
          idt1: formData.documentType,
          id1: formData.documentNumber,
        },
        bn: formData.accountNumber,
        s: formData.street,
        sno: formData.buildingNumber,
        z: formData.zipCode,
        c: formData.city,
        e1: formData.activity,
        mt: Date.now(),
      };

      backLib.backendUpdateUserEntity('userData', userId, mappedDataToSend)
        .then(() => setShowSuccessNotification(true))
        .catch(() => setShowFailNotification(true));
    } else {
      console.log('There is some errors in form!');
    }
  };

  // Check if all fields are returned from BE
  const isAllDataSet = (): boolean => {
    let hasData = false;
    Object.entries(backendData).forEach(([key, value]) => {
        if (key && !value.length) {
          hasData = true;
        }
    });
    return hasData;
  };

  useEffect(() => {
    backLib.backendLoadUser(userId).then((userData: any) => {
      const dataModel = {
        accountNumber: userData.bn || '',
        receiverName: userData.dn || '',
        pesel: userData.v ? userData.v.i1 || '' : '',
        street: userData.s || '',
        buildingNumber: userData.sno || '',
        zipCode: userData.z || '',
        city: userData.c || '',
        documentType: userData.v ? userData.v.idt1 || '' : '',
        documentNumber: userData.v ? userData.v.id1 || '' : '',
        activity: userData.e1 || '',
      };

      setBackendData(dataModel);
      setFormData(dataModel);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSuccessNotification]);

  return (
    <div className={`${styles.profile_wrapper}`}>
      <div className={`container ${styles.profile_container}`}>
        <h1 className={`title ${styles.profile_title}`}>{t('profile')}</h1>
        <div>
          <h2 className={`title ${styles.profile_subTitle}`}>{t('account_data')}</h2>
          <div className={styles.profile_readOnlyData}>
            <div className={styles.profile_readOnlyData__label}>{t('email')}</div>
            <div className={styles.profile_readOnlyData__text}>{email}</div>
          </div>
        </div>
        <div>
          <h2 className={`title ${styles.profile_subTitle}`}>{t('payback_data')}</h2>
          <form onSubmit={handleSubmit}>
            <InputMask
              mask="99 9999 9999 9999 9999 9999 9999"
              onChange={handleChange}
              value={formData.accountNumber}
              disabled={!!backendData.accountNumber.length}
            >
              {
                () => (
                  <CssTextField
                    fullWidth
                    autoComplete="off"
                    name="accountNumber"
                    label={`${t('bank_account_number')}*`}
                    placeholder={t('account_number')}
                    helperText={accountNumberError}
                    margin="normal"
                    variant="outlined"
                    type="tel"
                    InputLabelProps={{ shrink: true }}
                    error={!!accountNumberError}
                    disabled={!!backendData.accountNumber.length}
                  />
                )
              }
            </InputMask>
            <CssTextField
              fullWidth
              autoComplete="off"
              name="receiverName"
              label={`${t('receiver_name')}*`}
              placeholder={t('name_and_surname')}
              helperText={receiverNameError}
              margin="normal"
              variant="outlined"
              type="text"
              InputLabelProps={{shrink: true}}
              onChange={handleChange}
              error={!!receiverNameError}
              value={formData.receiverName}
              disabled={!!backendData.receiverName.length}
            />
            <InputMask
              mask="9 9 9 9 9 9 9 9 9 9 9"
              onChange={handleChange}
              value={formData.pesel}
              disabled={!!backendData.pesel.length}
            >
              {
                () => (
                  <CssTextField
                    fullWidth
                    autoComplete="off"
                    name="pesel"
                    label={`${t('pesel')}*`}
                    placeholder={t('pesel')}
                    helperText={peselError}
                    margin="normal"
                    variant="outlined"
                    type="tel"
                    InputLabelProps={{ shrink: true }}
                    error={!!peselError}
                    disabled={!!backendData.pesel.length}
                  />
                )
              }
            </InputMask>
            <FormControl
              fullWidth
              className={classes.formControl}
            >
              <InputLabel
                shrink
                htmlFor="documentType-native-label-placeholder"
                className={classes.selectLabel}
              >
                {`${t('document_type')}*`}
              </InputLabel>
              <CssSelect
                native
                value={formData.documentType}
                onChange={handleSelectChange}
                fullWidth
                variant="outlined"
                inputProps={{
                  name: 'documentType',
                  id: 'documentType-native-label-placeholder',
                }}
                disabled={!!backendData.documentType.length}
                error={!!documentTypeError}
              >
                <option value="">{t('please_choose')}</option>
                {
                  documentTypes.map((element: DocumentType, index: number) => (
                    <option key={index} value={element.code}>{element.name}</option>
                  ))
                }
              </CssSelect>
              {documentTypeError && <FormHelperText error>{documentTypeError}</FormHelperText>}
            </FormControl>
            <InputMask
              mask={formData.documentType === 'P' ? '********************' : 'aaa 999999'}
              maskChar={formData.documentType === 'P' ? ' ' : '_'}
              onChange={handleChange}
              value={formData.documentNumber}
              disabled={!!backendData.documentNumber.length}
            >
              {
                () => (
                  <CssTextField
                    fullWidth
                    autoComplete="off"
                    name="documentNumber"
                    label={`${t('document_number')}*`}
                    placeholder={t('document_number')}
                    helperText={documentNumberError}
                    margin="normal"
                    variant="outlined"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                    error={!!documentNumberError}
                    disabled={!!backendData.documentNumber.length}
                  />
                )
              }
            </InputMask>
            <CssTextField
              fullWidth
              autoComplete="off"
              name="street"
              label={`${t('street')}*`}
              placeholder={t('street')}
              helperText={streetError}
              margin="normal"
              variant="outlined"
              type="text"
              InputLabelProps={{shrink: true}}
              onChange={handleChange}
              value={formData.street}
              error={!!streetError}
              disabled={!!backendData.street.length}
            />
            <CssTextField
              fullWidth
              autoComplete="off"
              name="buildingNumber"
              label={`${t('building_number')}*`}
              placeholder={t('building_number')}
              helperText={buildingNumberError}
              margin="normal"
              variant="outlined"
              type="text"
              InputLabelProps={{shrink: true}}
              onChange={handleChange}
              value={formData.buildingNumber}
              error={!!buildingNumberError}
              disabled={!!backendData.buildingNumber.length}
            />
            <InputMask
              mask="99-999"
              onChange={handleChange}
              value={formData.zipCode}
              disabled={!!backendData.zipCode.length}
            >
              {
                () => (
                  <CssTextField
                    fullWidth
                    autoComplete="off"
                    name="zipCode"
                    label={`${t('zip_code')}*`}
                    placeholder={t('zip_code')}
                    helperText={zipCodeError}
                    margin="normal"
                    variant="outlined"
                    type="tel"
                    InputLabelProps={{shrink: true}}
                    error={!!zipCodeError}
                    disabled={!!backendData.zipCode.length}
                  />
                )
              }
            </InputMask>
            <CssTextField
              fullWidth
              autoComplete="off"
              name="city"
              label={`${t('city')}*`}
              placeholder={t('city')}
              helperText={cityError}
              margin="normal"
              variant="outlined"
              type="text"
              InputLabelProps={{shrink: true}}
              onChange={handleChange}
              value={formData.city}
              error={!!cityError}
              disabled={!!backendData.city.length}
            />
            <FormControl
              fullWidth
              className={classes.formControl}
            >
              <InputLabel
                shrink
                htmlFor="activities-native-label-placeholder"
                className={classes.selectLabel}
              >
                {`${t('where_collect')}*`}
              </InputLabel>
              <CssSelect
                native
                value={formData.activity}
                onChange={handleSelectChange}
                fullWidth
                variant="outlined"
                inputProps={{
                  name: 'activity',
                  id: 'activities-native-label-placeholder',
                }}
                disabled={!!backendData.activity.length}
                error={!!activityError}
              >
                <option value="">{t('please_choose')}</option>
                {
                  activities.map((element: ActivityType, index: number) => (
                    <option key={index} value={element.code}>{element.name}</option>
                  ))
                }
              </CssSelect>
              {activityError && <FormHelperText error>{activityError}</FormHelperText>}
            </FormControl>
            <CssButton
              fullWidth
              size="large"
              variant="outlined"
              type="submit"
              disabled={!isAllDataSet()}
            >
              {t('save')}
            </CssButton>
          </form>
        </div>
      </div>
      {
        showFailNotification &&
        <Notification
          type="error"
          msg={t('save_error')}
          autoHideDuration={time}
        />
      }
      {
        showSuccessNotification &&
        <Notification
          type="save"
          msg={t('save_success')}
          autoHideDuration={time}
        />
      }
    </div>
  );
};

export default Profile;
