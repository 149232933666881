import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { BackApp } from 'libs/App';
import { useTranslation } from 'react-i18next';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import MailOutline from '@material-ui/icons/MailOutline';
import LockOutlined from '@material-ui/icons/LockOutlined';
import { InputFieldStyle } from 'components/override_styles/TextField';
import { ButtonStyle } from 'components/override_styles/Button';
import {ReactComponent as FacebookIcon} from 'assets/facebook_icon.svg';

import Loading from 'components/loading/Loading';

import styles from './Login.module.scss';

// Initialize backend methods
const backLib = BackApp();

// Overridden material styles
const CssTextField = InputFieldStyle(TextField);
const CssButton = ButtonStyle(Button);

const Login = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState({login: '', password: ''});
  const [loader, setLoader] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const scrollToRef = useRef<HTMLDivElement>(null);

  const handleLogin = (event: React.FormEvent<EventTarget>) => {
    event.preventDefault();
    setLoader(true);
    backLib.userSignIn(user.login, user.password)
    .catch((error) => {
      console.log('Login error:', error);
      setLoginError(true);
      setLoader(false);
    });
  };

  const handleFacebookLogin = () => {
    backLib.userSignInWithFacebook();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setUser({...user, [event.target.name]: event.target.value});
  };

  // Scroll view when keyboard appear
  const scrollToButton = () => {
    const scrollAmount = 80;
    // Add a little timeout. We need this because keyboard has a little animation on appear.
    setTimeout(() => {
      // Scroll only when button is not fully visible
      if (scrollToRef.current && scrollToRef.current.scrollTop < scrollAmount) {
        scrollToRef.current.scrollTo({
          top: scrollAmount,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 500);
  };

  return (
    <>
      <div
        className={`${styles.login_wrapper} background_gradient background_gradient__purpure`}
        ref={scrollToRef}
      >
        <form className={`container ${styles.login_form}`} onSubmit={handleLogin}>
          <div className="title_log">
            {t('login_title')}
          </div>
          <CssTextField
            fullWidth
            autoComplete="off"
            name="login"
            label={t('email')}
            placeholder={t('email_placeholder')}
            helperText={loginError ? t('wrong_data') : ''}
            margin="normal"
            variant="outlined"
            type="email"
            InputLabelProps={{shrink: true}}
            InputProps={{startAdornment: (<InputAdornment position="start"><MailOutline /></InputAdornment>)}}
            onChange={handleChange}
            error={loginError}
            onFocus={scrollToButton}
          />
          <CssTextField
            fullWidth
            autoComplete="off"
            name="password"
            label={t('password')}
            placeholder={t('password_placeholder')}
            helperText={loginError ? t('wrong_data') : ''}
            margin="normal"
            variant="outlined"
            type="password"
            InputLabelProps={{shrink: true}}
            InputProps={{startAdornment: (<InputAdornment position="start"><LockOutlined /></InputAdornment>)}}
            onChange={handleChange}
            error={loginError}
            onFocus={scrollToButton}
          />
          <Link className={styles.login_resetLink} to="/reset-password">
            {t('forgot_password')}
          </Link>
          <CssButton
            fullWidth
            size="large"
            variant="outlined"
            type="submit"
          >
            {t('login')}
          </CssButton>
          <CssButton
            fullWidth
            size="large"
            variant="outlined"
            type="button"
            startIcon={<FacebookIcon className={styles.login_facebookIcon} />}
            onClick={handleFacebookLogin}
          >
            {t('login_facebook')}
          </CssButton>
          <div className={styles.login_signupText}>
            <div className={styles.login_signupText__nowrap}>{t('dont_have_account')}</div>
            <Link className={styles.login_signupLink} to="/sign-up">
              {t('sign_up')}
            </Link>
          </div>
        </form>
        {loader && <Loading />}
      </div>
    </>
  );
};

export default Login;
