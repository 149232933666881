import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { eMail } from 'utils/validators';

import Terms from 'components/terms/Terms';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import MailOutline from '@material-ui/icons/MailOutline';
import LockOutlined from '@material-ui/icons/LockOutlined';
import { InputFieldStyle } from 'components/override_styles/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { CheckboxStyle } from 'components/override_styles/Checkbox';
import { ButtonStyle } from 'components/override_styles/Button';

import styles from './Sign_up.module.scss';
import { BackApp } from 'libs/App';
import Loading from 'components/loading/Loading';
import Notification from 'components/notification/Notification';

// Overridden material styles
const CssTextField = InputFieldStyle(TextField);
const CssButton = ButtonStyle(Button);
const CssCheckbox = CheckboxStyle(Checkbox);

// Initialize backend methods
const backLib = BackApp();

type Props = {
  history: {
    push: (path: string) => void,
  },
  match: {
    params: {
      terms: string,
    },
  },
};

const SignUp = (props: Props) => {
  const [terms, setTerms] = useState(false);
  const [user, setUser] = useState({login: '', password: ''});
  const [loader, setLoader] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [errorEmailExist, setErrorEmailExistText] = useState('');
  const [checked, setChecked] = useState<boolean | undefined>();
  const { t } = useTranslation();
  const { match, history } = props;
  const scrollToRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (match.params.terms === 'terms') {
      toggleTerms();
    }
  }, []);

  const toggleTerms = () => {
    terms ? history.push('/sign-up') : history.push('/sign-up/terms');
    setTerms(!terms);
  };

  const toggleChecked = () => {
    setChecked(!checked);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUser({...user, [event.target.name]: event.target.value});
  };

  const handleSignUp = (event: React.FormEvent<EventTarget>) => {
    const isCorrectEmail = user.login.match(eMail);
    const isCorrectPasswordLength = user.password.length >= 8;

    event.preventDefault();
    setEmailError(true);
    setPasswordError(true);

    // Remove errors if passed data are correct
    if (isCorrectEmail) {setEmailError(false); }
    if (isCorrectPasswordLength) {setPasswordError(false); }

    // Submitting form
    if (isCorrectEmail && isCorrectPasswordLength && checked) {
      setLoader(true);

      backLib.userJoin(user.login, user.password)
        .then((msg) => {
          console.log('then:', msg);
          // By default firebase createUser function automatically login users in
          // So we logged it out and redirect to TYP
          BackApp().userSignOut().then(() => history.push('/sign-up-finish'));
          setLoader(false);
        })
        .catch(() => {
          setErrorEmailExistText(t('wrong_data'));
          setLoader(false);
        });
    } else {
      if (typeof checked === 'undefined') {
        setChecked(false);
      }
    }
  };

  const errorMessageNotification = (message: string, time: number) => {
    if (message) {
      // Clear error msg
      setTimeout(() => setErrorEmailExistText(''), time + 50);
      return (
        <Notification
          type="error"
          msg={message}
          autoHideDuration={time}
        />
      );
    }
  };

  // Scroll view when keyboard appear
  const scrollToButton = () => {
    const scrollAmount = 130;
    // Add a little timeout. We need this because keyboard has a little animation on appear.
    setTimeout(() => {
      // Scroll only when button is not fully visible
      if (scrollToRef.current && scrollToRef.current.scrollTop < scrollAmount) {
        scrollToRef.current.scrollTo({
          top: scrollAmount,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 500);
  };

  return (
    <>
      <div
        className={`${styles.signUp_wrapper} background_gradient background_gradient__purpure`}
        ref={scrollToRef}
      >
        <form className={`container ${styles.signUp_form}`} onSubmit={handleSignUp}>
          <div className="title_log">
            {t('sign_up_title')}
          </div>
          <CssTextField
            fullWidth
            autoComplete="off"
            name="login"
            label={t('email')}
            placeholder={t('email_placeholder')}
            helperText={emailError ? t('error_email') : ''}
            margin="normal"
            variant="outlined"
            type="email"
            InputLabelProps={{shrink: true}}
            InputProps={{startAdornment: (<InputAdornment position="start"><MailOutline /></InputAdornment>)}}
            onChange={handleChange}
            error={emailError}
            onFocus={scrollToButton}

          />
          <CssTextField
            fullWidth
            autoComplete="off"
            name="password"
            label={t('password')}
            placeholder={t('password_placeholder')}
            helperText={passwordError ? t('error_password') : ''}
            margin="normal"
            variant="outlined"
            type="password"
            InputLabelProps={{shrink: true}}
            InputProps={{startAdornment: (<InputAdornment position="start"><LockOutlined /></InputAdornment>)}}
            onChange={handleChange}
            error={passwordError}
            onFocus={scrollToButton}
          />
          <div className={styles.signUp_terms}>
            <CssCheckbox
              style={{color: typeof checked === 'undefined' || checked ? 'white' : 'red'}}
              value=""
              inputProps={{'aria-label': 'uncontrolled-checkbox'}}
              checked={checked}
              onClick={toggleChecked}
            />
            <span>
              {t('terms_accept_text1')}&nbsp;
              <span className={styles.signUp_termsLink} onClick={toggleTerms}>
                {t('terms_accept_link')}&nbsp;
              </span>
              {t('terms_accept_text2')}
            </span>
          </div>
          <CssButton
            fullWidth
            size="large"
            variant="outlined"
            type="submit"
          >
            {t('create_account')}
          </CssButton>
          <div className={styles.signUp_loginText}>
            {t('already_have_account')}&nbsp;
            <Link className={styles.signUp_loginLink} to="/login">
              {t('sign_in')}
            </Link>
          </div>
        </form>
        <Terms terms={terms} toggleTerms={toggleTerms} />
        {loader && <Loading />}

        {/* Error notification about the same users */}
        {errorMessageNotification(errorEmailExist, 3000)}
      </div>
    </>
  );
};

export default SignUp;
