
import moment from 'moment';
import { dayFormat } from 'utils/validators';

// Calculating height of tip by percentage, after that type of calculation is converted to number
const calcSummaryInvoiceByPercent = (tip: number | string, percent: number): string => (+tip * (percent / 100)).toFixed(2);

// Scroll to DOM element
const scrollTo = (height: number) => (
  window.scrollTo({
    behavior: 'smooth',
    top: height,
  })
);

// Convert price from cents to amount with decimals ex. '12.50'
const priceDecimalString = (price: number) => (price * 0.01).toFixed(2);

// Full day format ex '2019-01-01T12:00'
const fullDayFormat = (time: number) => moment(time).toISOString(true).substr(0, 16);

// Get days between start and end day
const rangeDates = (startDate: any, endDate: any) => {
  const now = moment(startDate);
  const dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format(dayFormat));
    now.add(1, 'days');
  }
  return dates;
};

// Sum tips functions filtered by transaction type
const sumTipsOfAllObject = (arr: any, today: string, transaction: string[]) => {
  return arr[today].reduce((total: any, current: any) => {
    if (transaction.includes(current.t)) {
      total += current.a;
    }
    return total;
  }, 0);
};

// Count daily height of tip
const countDailyTip = (arr: any, today: string, transaction: string[]) => {
  if (arr[today]) {
    const daily = sumTipsOfAllObject(arr, today, transaction);
    return priceDecimalString(daily);
  }
  // If current day is without any Tips, display 0
  return '0,00';
};

// Sum total tips depends on dates range
const countHeightOfRangeAmount = (range: string[], data: any, transaction: string[]) => {
  const totalTip = range.map((elem: any) => {
    if (Object.keys(data).includes(elem)) {
      return sumTipsOfAllObject(data, elem, transaction);
    }
  }).filter((d: any) => d).reduce((prev: any, current: any) => prev + current, 0);

  return priceDecimalString(totalTip);
};

// filter history data passing current resultData, type of transaction ('tip', 'shared') and activate sloik ID
const filterHistoryData = (data: any, filterType: string, sloikID: string) => {
  const filteredObject = {};

  Object.entries(data).forEach((el: any) => {
    const keyObject = el[0];
    let valueObject;

    if (filterType === 'activeSloik') {
      valueObject = el[1].filter((item: any) => item.sh === sloikID);
    }
    if (filterType === 'myTip') {
      
      // excluded transaction type
      const excludedTransaction = ['S', 'SW'];
      const verificationCompleted = (data: {t: null | string, s: null | string}) => !(data.t === 'V' && data.s !== 'C');

      valueObject = el[1].filter((item: any) => item.sh !== sloikID && !excludedTransaction.includes(item.t) && verificationCompleted(item));
    }

    if (valueObject.length >= 1) {
      Object.assign(filteredObject, {[keyObject]: valueObject.length >= 1 ? valueObject : null });
    }
  });
  return filteredObject;
};

export {
  calcSummaryInvoiceByPercent,
  scrollTo,
  priceDecimalString,
  fullDayFormat,
  rangeDates,
  countDailyTip,
  countHeightOfRangeAmount,
  filterHistoryData,
};
