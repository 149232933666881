import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { BackApp } from 'libs/App';

import styles from './Logout.module.scss';

import { ReactComponent as Logo } from 'assets/pay-tip-logo-with-leafs.svg';

const Logout = () => {
  const { t } = useTranslation();

  useEffect(() => {
    BackApp().userSignOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${styles.logout} background_gradient background_gradient__gray`}>
      <Logo className={styles.logout_jar} />
      <div className={styles.logout_title}>{t('logged_out')}</div>
      <Link className={styles.logout_logoutLink} to="/login">
        {t('login_again')}
      </Link>
    </div>
  );
};

export default Logout;
