import React, { useState, useContext, useEffect } from 'react';
import UserConsumer from 'context/user/User';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter, NavLink, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import CloseIcon from '@material-ui/icons/Close';

import Switch from '@material-ui/core/Switch';
import { SwitchToggle } from 'components/override_styles/SwitchToggleButton';
import profilSVG from 'assets/profil.svg';
import sloikSVG from 'assets/sloik.svg';
import closeSVG from 'assets/close_purple.svg';

import { Routes } from 'config/Routes';

import InfoBox from 'components/layout/infoBox/InfoBox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import styles from './App_header.module.scss';

// Overridden material styles
const CssAppBar = withStyles({
  root: {
    backgroundColor: 'white',
  },
})(AppBar);

const CssToolbar = withStyles({
  root: {
    justifyContent: 'space-between',
    minHeight: '5rem',
  },
})(Toolbar);

const CssMenuIcon = withStyles({
  root: {
    fontSize: '3rem',
  },
})(MenuIcon);

const CssIconButton = withStyles({
  root: {
    color: 'black',
    marginRight: '-.7rem',
    padding: '.7rem',
  },
  '@global': {
    '.header_menuClose': {
      color: '#404040',
      margin: 0,
      padding: '1.2rem',
      position: 'absolute',
      justifySelf: 'end',
    },
  },
})(IconButton);

const CssSwipeableDrawer = withStyles({
  root: {
    '& .MuiDrawer-paper': {
      width: '82%',
      maxWidth: '30rem',
      display: 'grid',
      gridTemplateColumns: 'auto 6rem',
      alignItems: 'start',
    },
    '& .MuiBackdrop-root': {
      background: 'transparent',
    },
  },
})(SwipeableDrawer);

const CssCloseIcon = withStyles({
  root: {
    fontSize: '3rem',
  },
})(CloseIcon);

const CssSwitchToggle = SwitchToggle(Switch);

// Props types
type Props = {
  showMenu?: boolean,
  modal: boolean,
  changed: () => void,
  closeModal: () => void,
};

// Declare page without Header
const pageWithoutHeader = ['/'];

type ContextProps = {
  currentUser: {
    iOS: boolean,
    singleModule: boolean,
    tipBoxName: string,
  },
};

const AppHeader: React.FC<Props & RouteComponentProps> = ({showMenu = false, changed, closeModal, modal, location: { pathname }}) => {
  const userConsumer = useContext<Partial<ContextProps>>(UserConsumer);
  const { currentUser: { iOS, singleModule, tipBoxName } }: any = userConsumer;

  const { t } = useTranslation();
  const [menu, setMenu] = useState(false);

  const toggleMenu = () => setMenu(!menu);

  const HeaderMenu = () => (
    <CssIconButton
      edge="end"
      color="primary"
      aria-label="menu"
      onClick={toggleMenu}
    >
      <CssMenuIcon />
    </CssIconButton>
  );

  const MenuBuilder = () =>
    Routes.sort((a, b) => (a.menuOrder - b.menuOrder)).map((route, index) => {
      if (route.menuOrder) {
        return (
          <li key={index} className={styles.header_menuItems__item}>
            <NavLink
              className={styles.header_menuItems__link}
              activeClassName={styles.header_menuItems__linkActive}
              to={route.path}
              onClick={toggleMenu}
            >
              {t(route.translation)}
            </NavLink>
          </li>
        );
      }
      return null;
    });

  if (!pageWithoutHeader.includes(pathname)) {
    return (
      <CssAppBar position="static" elevation={0}>
        <CssToolbar>
          <Link to={'/login'} className={styles.header_logo}>
            <div className={styles.header_logoTitle}>{t('app_name')}</div>
          </Link>
          {
            showMenu && (
              <>
                <div className={styles.header_switchButton}>
                  <img
                    src={singleModule ? profilSVG : sloikSVG}
                    className={styles.header_switchButton__icon}
                    style={{ marginLeft: singleModule ? '11px' : '23px', marginTop: singleModule ? '-3px' : '' }}
                    height={singleModule ? '15' : '11'}
                    alt=""
                  />
                  {
                    // Show TipBox toggle only on this two paths
                    (pathname === '/tips' || pathname === '/balance') &&
                    (
                      <>
                        <CssSwitchToggle
                          checked={singleModule}
                          onChange={() => changed()}
                          value={singleModule}
                        />
                        {
                          !singleModule && <span className={styles.header_switchButton__activeSloik}>{tipBoxName}</span>
                        }
                      </>
                    )
                  }
                </div>
                <HeaderMenu />
              </>
            )
          }

          <Dialog
            open={modal}
            onClose={() => closeModal()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={styles.header_modal}
          >
            <DialogContent>
              <InfoBox text={t('information')} />
              <DialogContentText id="alert-dialog-description">{t('noActiveTipbox')}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <span className="MuiTypography-colorTextSecondary">{t('goFurther')}:</span>
              <Link to={'/tipbox'} onClick={() => closeModal()}>{t('tipBox')}</Link>
            </DialogActions>
            <img
              src={closeSVG}
              className={styles.header_modal__close}
              onClick={() => closeModal()}
              width="15"
              alt=""
            />
          </Dialog>

          <CssSwipeableDrawer
            elevation={0}
            anchor="right"
            open={menu}
            onClose={toggleMenu}
            onOpen={() => null}
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
          >
            <ul className={styles.header_menuItems}>
              {MenuBuilder()}
            </ul>
            <CssIconButton
              className="header_menuClose"
              aria-label="close"
              onClick={toggleMenu}
            >
              <CssCloseIcon />
            </CssIconButton>
          </CssSwipeableDrawer>
        </CssToolbar>
      </CssAppBar>
    );
  }
  return null;
};

export default withRouter(AppHeader);
