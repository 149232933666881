import React, { memo, useEffect, useState } from 'react';
import styles from './Boxes.module.scss';
import { useTranslation } from 'react-i18next';
import { BackApp } from 'libs/App';

import Select from '@material-ui/core/Select';
import { SelectStyle } from 'components/override_styles/SelectField';
import firebase from 'firebase';
import SpinnerSmall from 'components/layout/spinner/SpinnerSmall';
import userSVG from 'assets/user.svg';
import starSVG from 'assets/star.svg';

// Type of account
const PROFILE = `${process.env.REACT_APP_PROFILE}`;
const SLOIK = `${process.env.REACT_APP_SLOIK}`;

// Initialize backend methods
const backLib = BackApp();

type Props = {
  userId: string,
  as: string,
  setAS: (id: string) => void,
  setSM: (val: boolean) => void,
  setTipBoxName: (name: string) => void,
};

// Overridden material styles
const CssSelect = SelectStyle(Select);

// const InfoBox = (props: Props) => {
const Boxes = (props: Props) => {
  const { userId, as, setAS, setSM, setTipBoxName } = props;
  const { t } = useTranslation();
  const [list, setList] = useState<object[] | undefined>([]);
  const [tipboxID, setCurrentTipboxID] = useState<string>('');
  const [tipObject, setTipObject] = useState<object>({});

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    getTipboxList();

    // If data are loaded and sloik is activated set up sloik ID and load participants
    if (list) {
      if (as) {
        setCurrentTipboxID(as);
        loadParticipians();
      } else {
        setLoading(false);
      }
    }

    // Clear layout after disabled sloik mode
    if (!props.as) {
      setCurrentTipboxID('');
      setTipObject({});
    }

    return () => {
      firebase.database().ref(`/userShared/${userId}`).off();
    };
  }, [props]);

  // Get all available lists of sloik
  const getTipboxList = () => {
    firebase.database().ref(`/userShared/${userId}`).orderByChild('st').on('value', (snapshot: any) => {
      const resultArray: object[] = [];
      if (snapshot.val()) {
        snapshot.forEach((child: any) => {
          resultArray.push(child.val());
        });
      }
      setList(resultArray);
    });
  };

  // Load participants for active sloik
  const loadParticipians = () => {
    return backLib.loadActiveSharedParticipants().then((data: object) => {
      setLoading(false);
      setTipObject(data);
    }).catch(() => {
      setLoading(false);
      setError(true);
    });
  };

  // Update sloik data included active one, mode type and LS value
  const updateLayoutData = (activeSloik: any, type: boolean, lsOption: string, tipBoxName: string)  => {
    setAS(activeSloik);
    setSM(type);
    setTipBoxName(tipBoxName);
    localStorage.setItem('sm', lsOption);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const tipID = event.target.value;
    setCurrentTipboxID(tipID as string);
    setLoading(true);

    const index = (event as React.BaseSyntheticEvent<any>).nativeEvent.target.selectedIndex;
    const selectedTipBoxName = (event as React.BaseSyntheticEvent<any>).nativeEvent.target[index].text;

    if (tipID === '') {
      updateLayoutData(null, true, PROFILE, '');
    } else {
      updateLayoutData(tipID, false, SLOIK, selectedTipBoxName);
    }

    // Activate sloik and load participians if sloik is selected 
    backLib.backendUpdateUserEntity('userData', userId, { as: tipID }).then(() => {
      // Select current tipbox active
      // @ts-ignore
      setAS(tipID);
      if (tipID !== '') {
        loadParticipians();
      }
    }).catch(() => {
      setLoading(false);
      setError(true);
    });

  };

  return (
    <div className={styles.boxes_wrapper}>

      <div className={styles.boxes_select}>
        <h2 className="title title__small mb-10">{t('cumulateTips')}</h2>
        <CssSelect
          native
          value={tipboxID}
          onChange={handleChange}
          fullWidth
          variant="outlined"
        >
          <option value="">{t('empty')}</option>
          {
            list && list.map((el: any) => (
              <option key={el.s} value={el.s}>{el.l}</option>
            ))
          }
        </CssSelect>
      </div>

      <div className={styles.boxes_listBox}>
        {
          tipboxID && !loading ? (
            <>
              <h2 className="title title__small mb-10">{t('members')} <strong>({Object.keys(tipObject).length})</strong></h2>
              <ul className={styles.boxes_list}>
                {
                  Object.values(tipObject).map((item: any) => (
                  <li
                    key={item.n}
                    style={{ color: item.o && '#AA4399' }}
                    className={styles.boxes_listItem}
                  >
                    <img src={item.o ? starSVG : userSVG} width="15" alt=""/>
                    <strong>{item.n}</strong>
                    {item.o && <span>&nbsp;({t('leader')})</span>}
                  </li>
                  ))
                }
              </ul>
            </>
          ) : loading ? <SpinnerSmall /> : null
        }
      </div>
      {
        error && <h1 className="title title__err">{t('errMessage')}</h1>
      }
    </div>
  );
};

export default memo(Boxes);
