import { withStyles } from '@material-ui/core/styles';
import Switch, { SwitchProps } from '@material-ui/core/Switch';
import { lightBlue } from '@material-ui/core/colors';

export const SwitchToggle = (component: React.ComponentType<SwitchProps>) => withStyles({
  root: {
    padding: '0',
    borderRadius: '30px',
    height: '30px',
    '& .MuiIconButton-root': {
      backgroundColor: '#E9649B',
      paddingTop: '5px',
      transform: 'translateX(-3px)',
    },
    '& .MuiIconButton-root + .MuiSwitch-track': {
      backgroundColor: '#E9649B',
      opacity: '1',
    },
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      backgroundColor: '#2B0096',
      color: 'white',
      paddingTop: '5px',
      transform: 'translateX(80%)',
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#2B0096',
      opacity: '1',
    },
  },
  thumb: {
    width: 14,
    height: 14,
    marginTop: 3,
  },
  checked: {},
})(component);
