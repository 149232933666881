import { withStyles } from '@material-ui/core/styles';
import { TextFieldProps } from '@material-ui/core/TextField';

export const InputFieldBlikStyle = (component: React.ComponentType<TextFieldProps>) => withStyles({
  root: {
    margin: '2rem 0',
    '& .MuiInputLabel-formControl': {
      position: 'relative',
      fontFamily: '"Montserrat"',
      fontSize: '1.4rem',
      color: '#404040',
      marginBottom: '.8rem',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'none',
    },
    '& .Mui-focused fieldset': {
      border: '2px solid #CDD8EF',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #CDD8EF',
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      color: '#E9649B',
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #E9649B',
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
      color: '#CDD8EF',
    },
    '& .MuiOutlinedInput-root': {
      color: '#404040',
      fontFamily: '"Montserrat"',
      fontSize: '1.4rem',
      background: 'white',
      borderRadius: '.6rem',
      height: '4rem',
      '& legend': {
        display: 'none',
      },
      '& fieldset': {
        border: '1px solid #CDD8EF',
        borderRadius: '.6rem',
        transition: 'none',
        top: 0,
      },
      '&:hover fieldset': {
        border: '2px solid #CDD8EF',
      },
      '& .Mui-focused fieldset': {
        border: '2px solid #CDD8EF',
      },
      '& .MuiOutlinedInput-input': {
        textAlign: 'center',
        letterSpacing: '.2rem',
        fontSize: '2rem',
        fontWeight: 700,
      },
      '& .MuiSvgIcon-root': {
        fontSize: '2rem',
      },
    },
    '& .MuiFormHelperText-contained': {
      fontFamily: '"Montserrat"',
      margin: '.5rem 0 0 0',
      fontSize: '1.2rem',
      color: '#E9649B',
    },
  },
})(component);
