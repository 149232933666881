import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BackApp } from 'libs/App';

import styles from './About.module.scss';
import Scrollbars from 'react-custom-scrollbars';
import { Link } from 'react-router-dom';
import Terms from 'components/terms/Terms';

// Initialize backend methods
const backLib = BackApp();

type Props = {
  history: {
    push: (path: string) => void,
  },
  match: {
    params: {
      terms: string,
    },
  },
};

const About = (props: Props) => {
  const { t } = useTranslation();
  const [terms, setTerms] = useState(false);
  const { match, history } = props;

  // Add translations with html tags by dangerouslySetInnerHTML
  const createMarkup: any = () => ({__html: t('about_whats_new')});

  const toggleTerms = () => {
    terms ? history.push('/about') : history.push('/about/terms');
    setTerms(!terms);
  };

  useEffect(() => {
    if (match.params.terms === 'terms') {
      toggleTerms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="background_gradient background_gradient__gray">
      <div className={`container ${styles.about}`}>
        <div className="title_log">
          {t('about_payTip')}
        </div>
        <Scrollbars thumbSize={90} style={{width: '100%', height: 'calc(100vh - 17rem)'}}>
          <div className={styles.about_smallTitle}>
            {t('version')}
          </div>
          <div className={styles.about_versionNumbers}>
            <span className={styles.about_versionNumbers__margin}>{backLib.version}</span>
            <span className={styles.about_versionNumbers__margin}>{backLib.updated}</span>
          </div>
          <div className={styles.about_smallTitle}>
            {t('whats_new_in_app')}
          </div>
          <div dangerouslySetInnerHTML={createMarkup()} className={styles.about_whatsNew} />
          <div className={styles.about_smallTitle}>
            {t('terms')}
          </div>
          <Link to="/about/terms" className={styles.about_termsLink}>
            {t('terms_and_rules')}
          </Link>
        </Scrollbars>
      </div>
      <Terms terms={terms} toggleTerms={toggleTerms} />
    </div>
  );
};

export default About;
