import { withStyles } from '@material-ui/core/styles';
import { ExtendButtonBase } from '@material-ui/core/ButtonBase';

export const ButtonStyle = (component: ExtendButtonBase<any>) => withStyles({
  root: {
    fontFamily: '"Montserrat"',
    margin: '2.5rem 0 .9rem 0',
    border: '2px solid white',
    borderRadius: '.6rem',
    color: 'white',
    textTransform: 'inherit',
    fontSize: '1.6rem',
  },
})(component);
