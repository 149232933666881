import React from 'react';
import styles from './ThankYou.module.scss';

type Props = {
  img: string,
  background: string,
  text?: string,
  additionalText?: string,
  link?: string,
  clicked?: () => void,
  children?: React.ReactNode,
};

const ThankYou: React.FC<Props> = ({ img, background, text, additionalText, link, clicked, children }) => {
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.images}`}>
        <div className={`${styles.images_bg}`}>
          <img src={require(`assets/${background}`)} width="80" alt=""/>
        </div>
        <img className={`${styles.images_icon}`} src={require(`assets/${img}`)} width="80" alt=""/>
      </div>
      <h1>{text}</h1>
      {
        additionalText && (<div className={`${styles.additionalText}`}>{additionalText}</div>)
      }
      {
        link && (<span className={`${styles.link}`} onClick={clicked}>{link}</span>)
      }
      {children}
    </div>
  );
};

export default ThankYou;
