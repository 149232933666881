import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import { ButtonStyle } from 'components/override_styles/Button';

import styles from './Sign_up_finish.module.scss';

// Overridden material styles
const CssButton = ButtonStyle(Button);

type Props = {
  history: {
    push: (path: string) => void,
  },
};

const SignUpFinish = (props: Props) => {
  const { t } = useTranslation();
  const { history } = props;

  const handleLogin = () => {
    history.push('/login');
  };

  return (
    <>
      <div className={`${styles.signUp_wrapper} background_gradient background_gradient__purpure`}>
        <div className={`container ${styles.signUp_container}`}>
          <div className="title_log">
            {t('sign_up_title')}
          </div>
          <div className={styles.signUp_info}>
            {t('sign_up_info')}
          </div>
          <CssButton
            className={styles.signUp_button}
            fullWidth
            size="large"
            variant="outlined"
            type="button"
            onClick={handleLogin}
          >
            {t('login')}
          </CssButton>
        </div>
      </div>
    </>
  );
};

export default SignUpFinish;
