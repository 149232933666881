import React, { useState } from 'react';

import styles from './Notification.module.scss';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import withStyles from '@material-ui/styles/withStyles';

type Props = {
  msg: string,
  buttonMsg?: string,
  autoHideDuration: number | null,
  buttonFunction?: () => any,
  type: string,
};

const Notification = (props: Props) => {
  const { msg, autoHideDuration, buttonMsg, buttonFunction, type } = props;
  let typeColor: string;
  let Icon: JSX.Element;

  switch (type) {
    case 'update': {
      typeColor = '#AA4399';
      Icon = <InfoIcon />;
      break;
    }
    case 'error': {
      typeColor = '#E9649B';
      Icon = <ErrorIcon />;
      break;
    }
    case 'info': {
      typeColor = '#AA4399';
      Icon = <InfoIcon />;
      break;
    }
    case 'save': {
      typeColor = '#2B0096';
      Icon = <CheckCircleIcon />;
      break;
    }
    default: {
      typeColor = '#AA4399';
      Icon = <InfoIcon />;
      break;
    }
  }

  const CssSnackbar = withStyles({
    root: {
      border: `2px solid ${typeColor}`,
      borderRadius: '.6rem',
      '& .MuiTypography-root': {
        color: typeColor,
        background: 'white',
      },
      '& .MuiSnackbarContent-root': {
        fontSize: '1.4rem',
        flexWrap: 'inherit',
        padding: '3px 16px',
      },
      '& .MuiSnackbarContent-action': {
        paddingLeft: '1rem',
        marginRight: '-1.4rem',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '2.2rem',
        verticalAlign: 'middle',
      }
    },
  })(Snackbar);

  const CssButton = withStyles({
    root: {
      fontSize: '1.4rem',
      fontWeight: 400,
      border: `1px solid ${typeColor}`,
    },
  })(Button);

  const CssIconButton = withStyles({
    root: {
      marginLeft: '0.2rem',
    },
  })(IconButton);

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CssSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      onClose={handleClose}
      autoHideDuration={autoHideDuration}
      message={
        <div className={styles.notification_msgWrapper}>
          <div>{Icon}</div>
          <div>{msg}</div>
        </div>
      }
      action={[
        (
          buttonFunction &&
          <CssButton key="button" color="secondary" onClick={buttonFunction}>
            {buttonMsg}
          </CssButton>
        ),
        <CssIconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </CssIconButton>,
      ]}
      ClickAwayListenerProps={{ onClickAway: () => null }}
    />
  );
};

export default Notification;
