import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import { ButtonStyle } from 'components/override_styles/Button';

import styles from './Reset_password_finish.module.scss';

// Overridden material styles
const CssButton = ButtonStyle(Button);

type Props = {
  history: {
    push: (path: string) => void,
  },
};

const ResetPasswordFinish = (props: Props) => {
  const { t } = useTranslation();
  const { history } = props;

  const handleLogin = () => {
    history.push('/login');
  };

  return (
    <>
      <div className={`${styles.reset_wrapper} background_gradient background_gradient__purpure`}>
        <div className={`container ${styles.reset_container}`}>
          <div className="title_log">
            {t('reset_password_title')}
          </div>
          <div className={styles.reset_info}>
            {t('reset_password_info')}
          </div>
          <CssButton
            className={styles.reset_button}
            fullWidth
            size="large"
            variant="outlined"
            type="button"
            onClick={handleLogin}
          >
            {t('login')}
          </CssButton>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordFinish;
