export const pl = {
  app_name: 'PayTip',
  intro_text: 'Przyjmuj napiwki i spełniaj swoje marzenia!',
  login_title: 'Logowanie',
  email: 'E-mail',
  password: 'Hasło',
  email_placeholder: 'Podaj e-mail',
  password_placeholder: 'Podaj hasło',
  login: 'Zaloguj',
  forgot_password: 'Nie pamiętam hasła',
  wrong_data: 'Błędne dane',
  login_facebook: 'Zaloguj przez facebook',
  dont_have_account: 'Nie masz jeszcze konta PayTip?',
  sign_up: 'Zarejestruj się',
  sign_in: 'Zaloguj się',
  reset_password_title: 'Resetowanie hasła',
  reset_password_help: 'Podaj e-mail powiązany z Twoim kontem, a prześlemy tam link do zmiany hasła.',
  reset_password_info: 'Link do zmiany hasła został wysłany.',
  reset_password: 'Resetuj hasło',
  sign_up_title: 'Rejestracja',
  sign_up_info: 'Link aktywacyjny został wysłany na podany adres e-mail.',
  create_account: 'Załóż konto',
  hello: 'Witaj',
  im: 'jestem',
  terms_accept_text1: 'Zapoznałem się z',
  terms_accept_text2: 'oraz wyrażam zgodę na przetwarzanie moich danych.',
  terms_accept_link: 'regulaminem',
  error_email: 'Błędny adres email',
  error_password: 'Minimalna długość znaków: 8',
  already_have_account: 'Posiadasz już konto?',
  logged_out: 'Wylogowano',
  login_again: 'Zaloguj ponownie',
  newTip: 'Nowy napiwek',
  newTipBox: 'Nowy TipBox',
  tip: 'Napiwek',
  invoice_amount: 'Kwota rachunku',
  received_tips: 'Przyjęte napiwki',
  tips_history: 'Historia napiwków',
  history: 'Historia',
  my_balance: 'Moje saldo',
  my_tips: 'Moje napiwki',
  day: 'Dzień',
  week: 'Tydzień',
  month: 'Miesiąc',
  tipCode: 'TipCode',
  profile: 'Profil',
  editProfile: 'Edytuj Profil',
  about_payTip: 'O PayTip',
  logout: 'Wyloguj',
  tip_height: 'Wysokość napiwku',
  currency: 'zł',
  paymentType: 'Płatność',
  writeBlickCodeOrTikCode: 'Podaj kod BLIK lub skorzystaj z TipKodu.',
  blikCode: 'Kod BLIK',
  tipCodeNewTip: 'TipKod',
  blikCodePlaceholder: ' 6 cyfr',
  tipPlaceholder: 'Np.: 29.00',
  pay: 'Płacę',
  tipWentToYourAccount: 'Napiwek trafił na Twoje konto',
  errMessage: 'Ups, coś poszło nie tak. Spróbuj ponownie',
  errMessage_maxReceipt: 'Maksymalna wysokość rachunku to',
  errMessage_minReceipt: 'Minimalna wysokość rachunku to',
  errMessage_maxTip: 'Maksymalna wysokość napiwku to',
  errMessage_minTip: 'Minimalna wysokość napiwku to',
  choosePaymentType: 'Wybierz formę płatności',
  giveTip: 'Wręczenie napiwku',
  writeBlikCode: 'Podaj kod BLIK',
  thankYouFortTIP: 'Dziękuje za napiwek',
  thankYou: 'Dziękujemy',
  thankYouPaymentRegister: 'Zlecenie zostało przyjęte do realizacji.',
  version: 'Wersja',
  whats_new_in_app: 'Co nowego w aplikacji',
  terms: 'Regulamin',
  terms_and_rules: 'Zasady i regulamin PayTip.io',
  terms_text: `
    <p>
      Cupcake ipsum dolortermsamet marshmallow bear claw bear claw fruitcake. Chocolate bar bonbon icing ice cream sesame snaps sweet roll gummies apple pie. Icing icing jelly candy.
    </p>
    <p>
      Toffee croissant crtermsnt chocolate sugar plum fruitcake icing. Croissant jelly-o cake. Lemon drops croissant sugar plum oat cake pastry.
    </p>
    <p>
      Tootsie roll jelly terms jelly beans toffee cheesecake sugar plum cotton candy gummi bears dessert. Sweet roll tootsie roll chocolate dessert cupcake toffee.
    </p>
    <p>
      Croissant donut cantermsnes tootsie roll halvah. Lollipop chupa chups sugar plum. Pudding lollipop cotton candy icing lemon drops powder apple pie chocolate. Marzipan jelly brownie.
    </p>
    <p>
      Gummi bears marzipan cupcake jelly caramels pie halvah. Jujubes cake lemon drops pudding cake cupcake carrot cake cheesecake. Lollipop liquorice ice cream. Cookie macaroon tart tiramisu jujubes liquorice jelly-o. Chocolate bar chupa chups dragée.
    </p>
  `,
  about_whats_new: `
    <div>
      <p>- Google Pay przy TipKodzie</p>
      <p>- TipKod wraz z poprawkami na ekranie dawania napiwku</p>
      <p>- Autouzupełnienia nazwy na TipCode</p>
      <p>- Generowanie QRcodes oraz obsługa wpłat z QRcodes</p>
      <p>- Wpięcie abee analityki</p>
      <p>- Poprawka księgowania wpłaty na wallecie usera pomniejszonej o prowizję</p>
      <p>- poprawka na wyświetlanie poniedziałkowych transakcji zaliczanych do bieżącego tygodnia</p>
      <p>- odbieranie powiadomienia z BLIK o udanej transakcji</p>
      <p>- integracja płatności BLIK z testowym PayU.</p>
    </div>
    <div>
      <p>Po wykonaniu napiwku płatność można zobaczyć pod linkiem <a href="/balance">tutaj</a></p>
      <p>- wypłata środków wymaga podania danych w profilu</p>
      <p>- dla konta nie facebook możliwość resetu hasła</p>
    </div>
  `,
  tipCodeIndividualText: 'To jest Twój indywidualny TipKod. Możesz go wydrukować i wręczyć dowolnej osobie z prośbą o napiwek.',
  tipCodeContent: 'Jeżeli jesteś zadowolony z obsługi przyznaj mi napiwek za pomocą tego kodu QR. Zeskanuj kod i postępuj zgodnie z info na ekranie.',
  tipCodeContentThankyou: 'Z góry dziękuję i polecam się na przyszłość!',
  new_update: 'Dostępna jest nowa wersja! Uruchom ponownie aplikację.',
  reload: 'Reload',
  tipCodeContentGive: 'Jeżeli jesteś zadowolony z obsługi przyznaj mi napiwek. Z góry dziękuję i polecam się na przyszłość!',
  giveTipButton: 'Przekaż napiwek',
  valueOfTipHeight: 'Proszę podać kwotę napiwku',
  entry: 'Dalej',
  save: 'Zapisz',
  account_data: 'Dane konta',
  user_id: 'User ID',
  payback_data: 'Dane do wypłaty',
  bank_account_number: 'Numer Twojego rachunku bankowego',
  account_number: 'Numer rachunku',
  receiver_name: 'Imię i nazwisko',
  pesel: 'PESEL',
  name_and_surname: 'Imię i nazwisko',
  street: 'Ulica',
  building_number: 'Numer budynku / lokalu',
  zip_code: 'Kod pocztowy',
  city: 'Miasto',
  wrong_account_number: 'Niepoprawny numer rachunku',
  required: 'Pole wymagane',
  walletOwnerTip: 'Aby zlecić wypłatę środków z TipBox musisz być jego leaderem.',
  individualMeasures: 'Środki indywidualne',
  walletMoreThanValue: 'Aby zlecić wypłatę musisz posiadać minimum',
  walletFillUserData: 'Aby zlecić wypłatę środków uzupełnij dane finansowe w profilu',
  walletSubmit: 'Środki zostaną przelane na wskazany w danych profilowych rachunek bankowy.',
  walletSubmitTipBox: 'Środki zostaną przekazane na konta indywidualne osób podłączonych do tego TipBoxa.',
  walletTransfer: 'Przelej środki',
  payout: 'Wypłata',
  save_error: 'Coś poszło nie tak. Dane nie zapisane.',
  save_success: 'Dane zostały zapisane.',
  no_data: 'Brak danych do wyświetlenia',
  information: 'Informacja',
  noActiveTipbox: 'Nie masz aktywnego TipBoxa. Aby aktywować wybierz z listy TipBoxów.',
  goFurther: 'Przejdź do',
  tipBox: 'TipBox',
  tipBoxes: 'TipBoxy',
  invite: 'Zaproś',
  createNew: 'Nowy',
  namePlaceholder: 'Nazwa',
  newTipBoxName: 'Nazwa TipBox musi zawierać co najmniej 3 znaki',
  create: 'Utwórz',
  cumulateTips: 'Teraz zbierasz napiwki do TipBoxa:',
  selectTipBox: 'Wybierz TipBox do zaproszenia:',
  tipBoxInviteInfo: 'Zeskanuj QR kod, żeby dołączyć do TipBoxa. Aby dołączyć odbiorca musi posiadać konto i aplikację PayTip.',
  empty: 'Brak',
  members: 'Uczestnicy',
  leader: 'Leader',
  toTipbox: 'Do TipBox',
  fromTipbox: 'Z TipBox',
  withdrawTipbox: 'Wypłata z tipbox',
  verificationTip: 'Weryfikacja',
  sharedTip: 'Shared tip',
  print_and_download_tipcodes: 'Pobierz i wydrukuj TipKody',
  set: 'Zestaw',
  set_info: 'Dodaj TipKod do każdego zamówienia',
  badge: 'Plakietka',
  badge_info: 'Informacja do zawieszenia w lokalu',
  do_you_like_it: 'Podoba Ci się?',
  leave_tip: 'Zostaw napiwek',
  print_info_text: 'Jeśli chcesz docenić nasz zespół zeskanuj QR kod i postępuj zgodnie z instrukcją.',
  scan_me: 'Zeskanuj mnie',
  inviting_tipBox: 'Trwa dołączanie do TipBoxa...',
  congratulations: 'Gratulacje',
  added_to_tipBox: 'Dołączyłeś/aś do TipBoxa',
  please_choose: 'Proszę wybrać',
  where_collect: 'Gdzie zbierasz napiwki',
  document_type: 'Typ dokumentu',
  document_number: 'Seria i numer dokumentu',
};
