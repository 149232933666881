import React, { useState, useEffect, useContext, useRef } from 'react';
import styles from './TipBox.module.scss';
import { RouteComponentProps } from 'react-router-dom';
import UserConsumer from 'context/user/User';
import { useTranslation } from 'react-i18next';

import AllTipBoxes from 'components/tipBoxes/boxes/Boxes';
import InviteTipBox from 'components/tipBoxes/invite/Invite';
import CreateNewTipBox from 'components/tipBoxes/create/Create';

import { ButtonBase } from '@material-ui/core';

const OPTION = ['tipBoxes', 'invite', 'createNew'];

type ContextProps = {
  currentUser: {
    userId: string,
    as: string | null,
    setAS: () => void,
    setSM: () => void,
    setTipBoxName: (name: string) => void,
  },
};

const TipBox = (props: RouteComponentProps) => {
  const userConsumer = useContext<Partial<ContextProps>>(UserConsumer);
  const { currentUser: { userId, as }, setAS, setSM, setTipBoxName }: any = userConsumer;
  const { t } = useTranslation();
  const [currentType, setCurrentType] = useState('tipBoxes');

  const selectHistoryType = (type: string) => setCurrentType(type);

  const tabs = () => {
    switch (currentType) {
      case 'tipBoxes':
        return <AllTipBoxes userId={userId} as={as} setAS={setAS} setSM={setSM} setTipBoxName={setTipBoxName} />;
      case 'invite':
        return <InviteTipBox userId={userId} />;
      case 'createNew':
        return <CreateNewTipBox userId={userId} selectTipBoxes={selectHistoryType}/>;
      }
    return null;
  };

  return (
    <div className={`${styles.tipBox_wrapper}`}>

      <div className="container">
        <h1 className={`title ${styles.tipBox_title} mb-15`}>{t('tipBox')}</h1>

        <div className={styles.tipBox_typesWrapper}>
          {
            OPTION.map((item) => (
              <ButtonBase key={item} onClick={() => selectHistoryType(item)}>
                <div className={`${styles.tipBox_types} ${item === currentType && styles.tipBox_types__active}`}>
                  <span className={styles.tipBox_typesItem}>{t(item)}</span>
                </div>
              </ButtonBase>
            ))
          }
        </div>

        {currentType && tabs()}
      </div>
    </div>
  );
};

export default TipBox;
