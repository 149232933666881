import React, { useEffect, useState } from 'react';
import styles from './GPay.module.scss';
import ButtonBase from '@material-ui/core/ButtonBase';

let paymentsClient: any = null;

// Add peymentsClient to window object
const getGooglePaymentsClient = () => {
  if (paymentsClient === null) {
    paymentsClient = new (window as any).google.payments.api.PaymentsClient({environment: 'TEST'});
  }
  return paymentsClient;
};

// Props types
type Props = {
  method: string,
  select: (value: any) => void,
  setGpayToken: (value: any) => void,
  setGpayClicked: (value: any) => void,
  gpayClicked: boolean,
  totalPrice: string,
};

const GPButton = (props: Props) => {
  const [readyToPay, setReadyToPay] = useState(false);

  // Google Pay request configuration
  const baseRequest = {
    environment: 'TEST',
    merchantInfo: {
      // merchantId: '01234567890123456789',
      merchantName: 'Paytip',
    },
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
          allowedCardNetworks: ['MASTERCARD', 'VISA'],
        },
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY',
          parameters: {
              'gateway': 'payu',
              'gatewayMerchantId': `${process.env.REACT_APP_GATEWAY_MERCHANT_ID}`,
          },
        },
      },
    ],
    transactionInfo: {
      countryCode: 'PL',
      currencyCode: 'PLN',
      totalPriceStatus: 'FINAL',
      totalPrice: props.totalPrice.toString(),
    },
  };

  // Add peymentsClient to window object when GPay script is loaded and if ready to pay setReadyToPay to true
  const onGooglePayLoaded = () => {
    paymentsClient = getGooglePaymentsClient();
    paymentsClient.isReadyToPay(baseRequest)
        .then((response: any) => {
          if (response.result) {
            setReadyToPay(true);
          }
        })
        .catch((err: any) => {
          // show error in developer console for debugging
          console.error(err);
        });
  };

  // Add Google Pay script
  const addScript = () => {
    const GOOGLE_PAY_BUTTON_SDK_URL = 'https://pay.google.com/gp/p/js/pay.js';
    const script = (window as any).document.createElement('script');
    script.src = GOOGLE_PAY_BUTTON_SDK_URL;
    // Add peymentsClient to window object when GPay script is loaded and if ready to pay setReadyToPay to true
    script.onload = onGooglePayLoaded;
    script.async = true;
    script.defer = true;
    (window as any).document.body.appendChild(script);
  };

  const processPayment = (paymentData: any) => {
    // Show returned data in developer console for debugging
    const paymentToken = paymentData.paymentMethodData.tokenizationData.token;
    props.setGpayToken(paymentToken);
  };

  // Pay button onClick
  const onGooglePaymentButtonClicked = () => {
    paymentsClient.loadPaymentData(baseRequest)
        .then((paymentData: any) => {
          // Handle the response
          processPayment(paymentData);
        })
        .catch((error: any) => {
          if (error.statusCode === 'CANCELED') {
            props.setGpayClicked(false);
          }
          // Show error in developer console for debugging
          console.error(error);
        });
  };

  // Only for styling purposes (active border)
  const selectMethod = () => props.select('gpay');

  useEffect(() => {
    // Add Google Pay script when component is in the view
    addScript();
  }, []);

  useEffect(() => {
    if (props.gpayClicked) {
      onGooglePaymentButtonClicked();
    }
  }, [props.gpayClicked]);

  // Add button only when GPay script is loaded and client is ready to pay
  return readyToPay ? (
    <ButtonBase onClick={selectMethod}>
      <div className={`${styles.GPay_paymentItem} ${props.method === 'gpay' && styles.GPay_paymentItem__selected}`}>
        <img src={require(`assets/gpay.svg`)} width="74" alt="" />
      </div>
    </ButtonBase>
  ) : null;
};

export default GPButton;
