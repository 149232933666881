import { withStyles } from '@material-ui/core/styles';
import { CheckboxProps } from '@material-ui/core/Checkbox';

export const CheckboxStyle = (component: React.ComponentType<CheckboxProps>) => withStyles({
  root: {
    color: 'white',
    marginTop: '-1rem',
    marginLeft: '-.9rem',
    '& .MuiSvgIcon-root': {
      fontSize: '2.2rem',
    },
  },
  '@global': {
    '.MuiCheckbox-colorSecondary.Mui-checked': {
      color: 'white',
    },
  },
})(component);
