import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Create.module.scss';
import { BackApp } from 'libs/App';

import Infobox from 'components/layout/infoBox/InfoBox';
import TextField from '@material-ui/core/TextField';
import { InputFieldStyle } from 'components/override_styles/TextFieldWhite';

import Button from '@material-ui/core/Button';
import { ButtonStyle } from 'components/override_styles/Button';
import SpinnerSmall from 'components/layout/spinner/SpinnerSmall';

// Initialize backend methods
const backLib = BackApp();

// Overridden material styles
const CssTextField = InputFieldStyle(TextField);
const CssButton = ButtonStyle(Button);

const VALIDATION_LENGTH = 3;

type Props = {
  userId: string,
  selectTipBoxes: (type: string) => void,
};

const Create = (props: Props) => {
  const { userId, selectTipBoxes } = props;
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => setName(e.currentTarget.value);

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();

    if (name.length >= VALIDATION_LENGTH) {
      setLoading(true);

      backLib.registerShared(name, userId).then((data) => {
        setLoading(false);
        setName('');
        selectTipBoxes('tipBoxes');
      }).catch(() => {
        setLoading(false);
        setError(true);
      });
    }
  };

  const validationForm = (inputText: string) => {
    return inputText.length < VALIDATION_LENGTH ? `${styles.create_paymentSectionButton__disabled}` : `${styles.create_paymentSectionButton__active}`;
  };

  return (
    <div className={styles.create_wrapper}>
      {
        !loading && !error ? (
          <>
            <form onSubmit={handleSubmit}>
              <CssTextField
                fullWidth
                autoComplete="off"
                name="newTipBox"
                label={t('newTipBox')}
                placeholder={t('namePlaceholder')}
                margin="normal"
                variant="outlined"
                type="text"
                InputLabelProps={{shrink: true}}
                onChange={(event: React.FormEvent<HTMLElement | any>): void => { handleChange(event); }}
              />
            </form>

            <Infobox text={t('newTipBoxName')} />

            <CssButton
              fullWidth
              className={validationForm(name)}
              disabled={name.length < VALIDATION_LENGTH}
              size="large"
              variant="outlined"
              type="button"
              onClick={handleSubmit}
            >
              {t('create')}
            </CssButton>
          </>
        ) : !error ? <SpinnerSmall /> : <h1 className="title title__err">{t('errMessage')}</h1>
      }

    </div>
  );
};

export default memo(Create);
