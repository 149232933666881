import React, { useState, useEffect, useRef, useContext, useLayoutEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import UserConsumer from 'context/user/User';
import { useTranslation } from 'react-i18next';
import { scrollTo } from 'utils/calculates';
import { BackApp } from 'libs/App';
import styles from './Give.module.scss';
import TextField from '@material-ui/core/TextField';
import { InputFieldBlikStyle } from 'components/override_styles/TextFieldBlik';
import InputMask from 'react-input-mask';
import PriceStyling from 'components/priceStyling/PriceStyling';

import ButtonBase from '@material-ui/core/ButtonBase';
import ThankYou from 'components/thankYou/ThankYou';

import Button from '@material-ui/core/Button';
import { ButtonStyle } from 'components/override_styles/Button';
import Loading from 'components/loading/Loading';
import GPButton from 'components/gpay/GPay';
import ApplePayButton from 'components/applepay/ApplePay';

// Initialize backend methods
const backLib = BackApp();

// Overridden material styles
const CssTextFieldBlik = InputFieldBlikStyle(TextField);
const CssButton = ButtonStyle(Button);

const paymentMethods = ['blik', 'gpay', 'applepay'];
const KEY = 0;
const VALUE = 1;

type ContextProps = {
  currentUser: {
    iOS: boolean,
  },
};

const Give = (props: RouteComponentProps) => {
  const userConsumer = useContext<Partial<ContextProps>>(UserConsumer);
  const { currentUser: { iOS } }: any = userConsumer;
  const { t } = useTranslation();
  const [method, selectMethod] = useState();
  const [blikCode, setBlikCode] = useState();
  const [activePaymentButton, setActivePaymentButton] = useState(false);

  // Naming for decoding URL parameters
  const [tipPrice, setTipPrice] = useState('0');
  const [userID, setUserId] = useState();
  const [name, setName] = useState();
  const [ff, setFlag] = useState();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [thankYouPage, setThankYouPage] = useState(false);
  const [applePayIsAvailable, setApplePayIsAvailable] = useState(false);

  // Reference to DOM object
  const blikCodeRef = useRef<HTMLInputElement>(null);
  const paymentButton = useRef<HTMLButtonElement>(null);

  const [gpayClicked, setGpayClicked] = useState(false);
  const [applepayClicked, setApplepayClicked] = useState(false);
  const [gpayToken, setGpayToken] = useState();
  const [applepayToken, setApplepayToken] = useState();

  // iOS numeric keyboard pattern
  const regex = /\d*/;

  useLayoutEffect(() => {
    if (paymentButton.current) {
      scrollTo(paymentButton.current.offsetTop);
    }
  }, [activePaymentButton]);

  useEffect(() => {
    checkIsApplePayIsAvailable();

    const parameters = props.location.search.replace('?', '').split('&');

    // Assign to variables destructing URL's data
    parameters.forEach((item) => {
      const data = item.split('=');

      switch (data[KEY]) {
        case 'a':
          setTipPrice(data[VALUE]);
          break;
        case 'r':
          setUserId(data[VALUE]);
          break;
        case 'n':
          setName(data[VALUE]);
          break;
        case 'f':
          setFlag(data[VALUE]);
          break;
        default:
          return;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Clear blikCode value, when payment method will be changed
  useEffect(() => {
    if (blikCodeRef.current) {
      blikCodeRef.current.value = '';
    }
  }, [method]);

  const selectedPaymentMethod = (item: string) => {
    if (blikCodeRef.current) {
      blikCodeRef.current.value = '';
    }
    selectMethod(item);
    setBlikCode(null);
    setActivePaymentButton(false);
    setError(false);
  };

  useEffect(() => {
    if (gpayToken || applepayToken) {
      setLoading(true);
      const tipHeightCalc = Math.floor(+tipPrice * 100);
      const methodType = method.charAt(0).toUpperCase();

      backLib.registerPayment(tipHeightCalc, gpayToken || applepayToken, userID, methodType)
        .then(() => {
          setLoading(false);
          setThankYouPage(true);
        })
        .catch(() => {
          setLoading(false);
          setError(true);
          selectMethod(null);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gpayToken, applepayToken]);

  const handleChangeBlik = (e: React.FormEvent<HTMLInputElement>) => {
    const clearValue = e.currentTarget.value.replace(/\s|_/g, '');

    if (clearValue.length === 6) {
      setBlikCode(clearValue);
      setActivePaymentButton(true);
    } else {
      setActivePaymentButton(false);
    }
  };

  const walletAccept = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();

    if (blikCode && blikCode.toString().length === 6) {
      setLoading(true);
      const amount = Math.floor(+tipPrice * 100);
      const methodType = method.charAt(0).toUpperCase();

      backLib.registerPayment(amount, blikCode, userID, methodType)
        .then(() => {
          setLoading(false);
          setThankYouPage(true);
        })
        .catch(() => {
          setLoading(false);
          setError(true);
          selectMethod(null);
        });
    }

    if (method === 'gpay') {
      setGpayClicked(true);
    }
    if (method === 'applepay') {
      setApplepayClicked(true);
    }
  };

  const paymentButtonElement = (item: string) => (
    <ButtonBase key={item} onClick={() => selectedPaymentMethod(item)}>
      <div className={`${styles.give_paymentItem} ${item === method && styles.give_paymentItem__selected}`}>
        <img src={require(`assets/${item}.svg`)} width="74" alt="" />
      </div>
    </ButtonBase>
  );

  const checkIsApplePayIsAvailable = () => {
    if ((window as any).ApplePaySession && (window as any).ApplePaySession.canMakePayments()) {
      const merchantIdentifier = 'merchant.sandbox.paytip.io';
      const  promise = (window as any).ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier);
      promise.then((canMakePayments: any) => {
        if (canMakePayments) {
          console.log('canMakePayments', canMakePayments);
          setApplePayIsAvailable(true);
        } else {
          console.log('No payments');
        }
      });
    }
  };

  return (
    <div className={`${styles.give_wrapper}`}>

      {/* Render thankyou page, when payment was successed */}
      <div style={{ display: thankYouPage ? 'grid' : 'none' }}>
        <ThankYou img="jam_jar.svg" background="jam_jar_bg.svg" text={t('thankYouFortTIP')}>
          <div className={`${styles.give_thankYouPage}`}>
            <PriceStyling tip={tipPrice} />
          </div>
        </ThankYou>
      </div>

      <div className="container" style={{ display: !thankYouPage ? 'grid' : 'none' }}>
      <h2 className={`title title__small`}>{t('giveTip')}</h2>

        {/* Customizing style for tip height */}
        <div className={`${styles.give_tip}`}>
          <h1 className={`title`}>{t('tip_height')}</h1>
          <PriceStyling tip={tipPrice} />
        </div>

        {/* Select payment method */}
        <div>
          <h1 className="title mt-30">{t('paymentType')}</h1>
          <p className={`${styles.give_subTitle}`}>{t('choosePaymentType')}</p>

          <div className={`${styles.give_payment}`}>
            {
              paymentMethods.map((item) => {
                if (iOS && item === 'applepay' && applePayIsAvailable) {
                  return (
                    <ApplePayButton
                      key={item}
                      method={method}
                      select={selectMethod}
                      applepayClicked={applepayClicked}
                      setApplepayClicked={setApplepayClicked}
                      setApplepayToken={setApplepayToken}
                      totalPrice={tipPrice}
                    />
                  );
                } else if (!iOS && item === 'gpay' && (window as any).PaymentRequest) {
                  return (
                    <GPButton
                      key={item}
                      method={method}
                      select={selectMethod}
                      gpayClicked={gpayClicked}
                      setGpayClicked={setGpayClicked}
                      setGpayToken={setGpayToken}
                      totalPrice={tipPrice}
                    />
                  );
                } else if (item === 'blik') {
                  return paymentButtonElement(item);
                }
              })
            }
          </div>

          {
            (method === 'blik' || !error) ? (
              <form onSubmit={walletAccept} noValidate>
                <div style={{ display: method === 'blik' ? 'grid' : 'none' }}>
                  <div className={`${styles.give_blikCode} mt-30`}>
                    <h1 className="title title__smallBold">{t('writeBlikCode')}</h1>
                    <InputMask
                      mask="999 999"
                      onChange={handleChangeBlik}
                    >
                      {
                        () => (
                          <CssTextFieldBlik
                            fullWidth
                            autoComplete="off"
                            name="blikCode"
                            placeholder={t('blikCodePlaceholder')}
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                              ref: blikCodeRef,
                              pattern: regex.source,
                              type: iOS ? 'text' : 'tel',
                            }}
                          />
                        )
                      }
                    </InputMask>
                  </div>

                  <CssButton
                    fullWidth
                    ref={paymentButton}
                    className={`${activePaymentButton ? styles.give_paymentSectionButton__active : styles.give_paymentSectionButton__disabled}`}
                    size="large"
                    variant="outlined"
                    type="submit"
                    disabled={!activePaymentButton}
                  >
                    {t('pay')}
                  </CssButton>
                </div>
              </form>
            ) : <h1 className="title title__err">{t('errMessage')}</h1>
          }
          {
            (method === 'gpay' || method === 'applepay') && (
              <form onSubmit={walletAccept} noValidate>
                <CssButton
                  fullWidth
                  ref={paymentButton}
                  className={styles.give_paymentSectionButton__active}
                  size="large"
                  variant="outlined"
                  type="submit"
                >
                  {t('pay')}
                </CssButton>
              </form>
            )
          }
        </div>

      </div>
      {loading && <Loading />}
    </div>
  );
};

export default Give;
