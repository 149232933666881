import React from 'react';
import { useTranslation } from 'react-i18next';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import logoImage from 'assets/logo.png';
import scanImage from 'assets/scan.png';
import buttonImage from 'assets/button_bg.png';
import fontMedium from 'assets/font/Montserrat-Medium.ttf';
import fontBold from 'assets/font/Montserrat-Bold.ttf';

// Register font
Font.register({ family: 'Montserrat', fonts: [
  { src: fontMedium },
  { src: fontBold, fontWeight: 700 },
 ]});

// Disable hyphenation
Font.registerHyphenationCallback((word) => [word]);

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    fontFamily: 'Montserrat',
  },
  logoSection: {
    textAlign: 'left',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: 10,
    padding: '20pt 20pt 70pt 0pt',
  },
  logoText: {
    color: '#404040',
    fontSize: 10,
    width: 'auto',
  },
  logo: {
    width: 48,
    marginLeft: 4,
  },
  contentSection: {
    margin: 10,
    padding: 10,
    // border: '1pt solid grey',
    flexDirection: 'column',
    textAlign: 'center',
  },
  header: {
    fontSize: 44,
  },
  buttonImage: {
    margin: '10pt 120pt 0pt 120pt',
  },
  button: {
    top: -37,
    color: 'white',
    fontWeight: 700,
    textTransform: 'uppercase',
    fontSize: 24,
    margin: '0pt 120pt 0pt 120pt',
  },
  infoText: {
    color: 'grey',
    margin: '0pt 120pt 20pt 120pt',
  },
  thanks: {
    color: 'grey',
    margin: 5,
    fontWeight: 700,
  },
  qrCodeWrapper: {
    marginTop: 40,
  },
  qrCode: {
    width: 180,
    height: 180,
  },
  scanImageWrapper: {
    marginTop: -15,
  },
  scanImage: {
    width: 140,
  },
});

type Props = {
  code: string,
};

const PrintFullPage = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.logoSection}>
          <Text style={styles.logoText}>Powered by</Text>
          <Image style={styles.logo} src={logoImage} />
        </View>
        <View style={styles.contentSection}>
          <Text style={styles.header}>{t('do_you_like_it')}</Text>
          <Image style={styles.buttonImage} src={buttonImage} />
          <Text style={styles.button}>{t('leave_tip')}!</Text>
          <Text style={styles.infoText}>{t('print_info_text')}</Text>
          <Text style={styles.thanks}>{t('thankYou')}!</Text>
          <Text style={styles.qrCodeWrapper}>
            <Image style={styles.qrCode} src={props.code} />
          </Text>
          <Text style={styles.scanImageWrapper}>
            <Image style={styles.scanImage} src={scanImage} />
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default PrintFullPage;
