import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AnimatedSwitch } from 'react-router-transition';
import styles from './App.module.scss';
import { initFirebase } from 'config/Firebase';
import * as firebase from 'firebase';
import { BackApp } from 'libs/App';
import Loading from 'components/loading/Loading';
import Notification from 'components/notification/Notification';

import AppHeader from 'components/app_header/App_header';
import { Routes } from 'config/Routes';

import { UserProvider } from 'context/user/User';

// Initialize firebase
initFirebase();
const backLib = BackApp();

// Type of account
const PROFILE = `${process.env.REACT_APP_PROFILE}`;
const SLOIK = `${process.env.REACT_APP_SLOIK}`;

type User = {
  isLoggedIn: boolean,
  userId: string,
  iOS: boolean,
  email: string,
  receiverName: null | string,
  singleModule: boolean,
  as: string,
  tipBoxName: string,
};

const App = () => {
  const { t } = useTranslation();
  let stateOfModule = true;

  // Read localStorage and set up switch button depends on account type
  // If there is no LS set singleModule means Indyvidual or Sloik Mode
  // Profile - 16, Słoik - 22
  if (localStorage.getItem('sm') && localStorage.getItem('sm') === SLOIK) { stateOfModule = false; }

  const [newServiceWorker, setNewServiceWorker] = useState(false);

  const [currentUser, setCurrentUser] = useState<User>({
    isLoggedIn: false,
    userId: '',
    iOS: false,
    email: '',
    receiverName: null,
    singleModule: true,
    as: '',
    tipBoxName: '',
  });

  const [configuration, setConfiguration] = useState({
    withdrawMinValue: null,
    tipReceiptMaxValue: null,
    tipMaxValue: null,
    tipMinValue: null,
    tipReceiptMinValue: null,
    withdrawTipboxMinValue: 0
  });
  const [responseReady, setResponseReady] = useState(false);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const [modal, setModal] = useState(false);

  const referralLink = localStorage.getItem('referralLink') || '';
  const url = window.location.pathname + window.location.search;

  // Show menu button only for logged in users
  useEffect(() => {
    backLib.initialized();
    // Check if user is logged
    firebase.auth().onAuthStateChanged((user: object | any) => {
      setResponseReady(true);
      if (user) {
        console.log('Logged:', user);
        setCurrentUser((d) => ({
          ...d,
          isLoggedIn: true,
          userId: user.uid,
          iOS: isIOS,
          email: user.email,
          singleModule: stateOfModule,
        }));

        backLib.backendLoadUser(user.uid).then((userData: any) => {
          if (userData) {
            if (userData.as) {
              getTipBoxName(user.uid, userData.as);
              setCurrentUser((d) => ({
                ...d,
                as: userData.as,
                singleModule: false,
              }));
            }
            setCurrentUser((d) => ({
              ...d,
              receiverName: userData.dn || '',
            }));
          }
        });
      } else {
        // No user is signed in.
        console.log('Not logged');
        // If user arrives from tipbox-invite and is not logged in then store his previous location
        storeLocation();
        setCurrentUser({
          isLoggedIn: false,
          userId: '',
          iOS: isIOS,
          email: '',
          receiverName: null,
          singleModule: stateOfModule,
          as: '',
          tipBoxName: '',
        });
      }
      // TODO: Make get all config parameters method
      backLib.loadConfig('withdraw_min_value')
        .then((val: number[]) => setConfiguration((prevState: any) => ({...prevState, withdrawMinValue: val[0]})));
      backLib.loadConfig('tip_receipt_max_value')
        .then((val: number[]) => setConfiguration((prevState: any) => ({...prevState, tipReceiptMaxValue: val[0]})));
      backLib.loadConfig('tip_max_value')
        .then((val: number[]) => setConfiguration((prevState: any) => ({...prevState, tipMaxValue: val[0]})));
      backLib.loadConfig('tip_min_value')
        .then((val: number[]) => setConfiguration((prevState: any) => ({...prevState, tipMinValue: val[0]})));
      backLib.loadConfig('tip_receipt_min_value')
        .then((val: number[]) => setConfiguration((prevState: any) => ({...prevState, tipReceiptMinValue: val[0] })));
      backLib.loadConfig('withdraw_tipbox_min_value')
        .then((val: number[]) => setConfiguration((prevState: any) => ({...prevState, withdrawTipboxMinValue: val[0] })));
    });

    // Listen to new version
    window.addEventListener('newContentAvailable', () => {
      // Then show notification
      setNewServiceWorker(true);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Clear referralLink after redirection to destination location
  // TODO: Consider move to "component did mount"
  useEffect(() => {
    if (currentUser.isLoggedIn && referralLink && (window.location.pathname + window.location.search) === referralLink) {
      localStorage.removeItem('referralLink');
      console.log('Redirect to referral');
    }
  }, [currentUser]);

  const RoutesBuilder = () => (
    // See Routes.tsx
    Routes.map((route, index) => {
      if (route.role === 3) {
        return (<Route key={index} path={route.path} exact={route.exact} component={route.component} />);
      }

      if (currentUser.isLoggedIn) {
        if (route.role === 2) {
          return (<Route key={index} path={route.path} exact={route.exact} component={route.component} />);
        } else {
          // If referral Link is set redirect user to it
          if (referralLink && window.location.pathname === '/login') {
            return (<Route key={index} path={route.path} exact={route.exact}><Redirect to={referralLink} /></Route>);
          }
          return (<Route key={index} path={route.path} exact={route.exact}><Redirect to="/new-tip" /></Route>);
        }
      }

      if (!currentUser.isLoggedIn && route.role === 1) {
        return (<Route key={index} path={route.path} exact={route.exact} component={route.component} />);
      } else {
        return (<Route key={index} path={route.path} exact={route.exact}><Redirect to="/login" /></Route>);
      }
    })
  );

  const switchAccountType = () => {
    setCurrentUser((opt: any) => {
      // Display modal, because user has not sloik option activated
      if (opt.singleModule) {
        if (!currentUser.as) {
          setModal(true);
          return ({ ...opt });
        }
        localStorage.setItem('sm', SLOIK);
        return ({ ...opt, singleModule: false });
      }

      // Set up localStorage to singleModule type, and clear activeSloik field on DB when user switchOff sloik mode
      if (!opt.singleModule) {
        localStorage.setItem('sm', PROFILE);
        return ({
          ...opt,
          singleModule: true,
        });
      }
    });
  };

  // Activate 'sloik' option
  const setAS = (id: string) => setCurrentUser((d: any) => ({...d, as: id}));

  // Set 'sloik' name
  const setTipBoxName = (name: string) => setCurrentUser((d: any) => ({...d, tipBoxName: name}));

  // Set up singleModule depends on sloik option
  const setSM = (val: boolean) => setCurrentUser((d: any) => ({...d, singleModule: val}));

  const closeModal = () => setModal(false);

  // If user arrives from tipbox-invite and is not logged in then store his previous location
  const storeLocation = () => {
    if (window.location.pathname === '/tipbox-invite') {
      localStorage.setItem('referralLink', url);
    }
  };

  // Get selected TipBox name
  const getTipBoxName = (userId: string, tipBoxId: string) => {
    firebase.database().ref(`/userShared/${userId}`).orderByChild('st').on('value', (snapshot: any) => {
      if (snapshot.val()) {
        snapshot.forEach((child: any) => {
          if (child.val().s === tipBoxId) {
            setCurrentUser((d) => ({
              ...d,
              tipBoxName: child.val().l,
            }));
          }
        });
      }
    });
  };

  return (
    <UserProvider
      value={{
        currentUser,
        setAS,
        setSM,
        setTipBoxName,
        configuration,
      }}
    >
      <Router>
        <AppHeader
          showMenu={currentUser.isLoggedIn}
          changed={switchAccountType}
          modal={modal}
          closeModal={closeModal}
        />
        <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className={styles.switchWrapper}
        >
          {/* Display spinner when response is not ready yet but not when entering intro page */}
          {!responseReady && window.location.pathname !== '/' ? <Loading /> : RoutesBuilder()}
        </AnimatedSwitch>
      </Router>
      { /* Show new service worker notification */
        newServiceWorker &&
        <Notification
          type="update"
          msg={t('new_update')}
          buttonMsg={t('reload')}
          autoHideDuration={null}
          buttonFunction={() => window.location.reload(true)}
        />
      }
    </UserProvider>
  );
};

export default App;
