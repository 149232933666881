import React, { useState, useEffect, useContext } from 'react';
import UserConsumer from 'context/user/User';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { ButtonBase } from '@material-ui/core';
import styles from './TipCode.module.scss';
import { useTranslation } from 'react-i18next';
import { BackApp } from 'libs/App';
import SpinnerSmall from 'components/layout/spinner/SpinnerSmall';
import PrintFullPage from 'views/print_fullpage/Print_fullpage';
import PrintMultiCards from 'views/print_multicards/Print_multicards';

// Initialize backend methods
const backLib = BackApp();

type ContextProps = {
  currentUser: {
    receiverName: null | string,
  },
};

const TipCode = () => {
  const userConsumer = useContext<Partial<ContextProps>>(UserConsumer);
  const { currentUser: { receiverName } }: any = userConsumer;
  const { t } = useTranslation();

  const [qrCode, setQrCode] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [pdfGenerationDelay, setPdfGenerationDelay] = useState(false);

  useEffect(() => {
    generateQRCode();
  }, []);

  useEffect(() => {
    // Prevent generating PDFDownloadLink in the same time
    const delayTimeout = setTimeout(() => setPdfGenerationDelay(true), 3000);
    return () => {
      clearTimeout(delayTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrCode]);

  const generateQRCode = () => {
    const hostname = `${process.env.REACT_APP_HOSTNAME}/tipcode-give`;

    backLib.generateQRCode(hostname, null, null, null)
      .then(({ u }: any) => {
        setLoading(false);
        setQrCode(u);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <div className={`${styles.tipCode_wrapper}`}>
      <div className={`container ${styles.tipCode_container}`}>
        <h1 className={`title ${styles.tipCode_title}`}>{t('tipCode')}</h1>

        <div>
          <p className={`${styles.tipCode_subTitle}`}>{t('tipCodeIndividualText')}</p>
          <div className={`${styles.tipCode_qrCodeWrapper}`}>
            {
              loading ? <SpinnerSmall /> : !error ? <img src={qrCode} width="115" alt=""/> : <h1 className="title title__err">{t('errMessage')}</h1>
            }
          </div>

          <div>
          <h2 className="title mt-30">{`${t('hello')}${receiverName !== null && receiverName ? `, ${t('im')} ${receiverName.split(' ')[0]}` : ''}`}</h2>
            <p className={`${styles.tipCode_subTitle}`}>{t('tipCodeContent')}</p>
            <p className={`${styles.tipCode_subTitle}`}>{t('tipCodeContentThankyou')}</p>
          </div>
        </div>
        <div>
          <h2 className="title mt-30">{t('print_and_download_tipcodes')}</h2>
          {
            qrCode && receiverName !== null ? (
              <div className={styles.tipCode_printButtonsWrapper}>
                <PDFDownloadLink document={<PrintMultiCards code={qrCode} multiply={10} name={receiverName ? receiverName.split(' ')[0] : ''} />} fileName="MultiCardsPage.pdf" className={styles.tipCode_printButtonsWrapper__link}>
                  {({ blob, url, loading, error }) => (loading ? (
                    <SpinnerSmall />
                  ) : (
                    <ButtonBase>
                      <div className={styles.tipCode_printButton}>
                        <div className={styles.tipCode_printButton__bold}>{t('set')}</div>
                        <div className={styles.tipCode_printButton__info}>{t('set_info')}</div>
                      </div>
                    </ButtonBase>
                  ))}
                </PDFDownloadLink>
                  {/* Prevent generating PDFDownloadLink in the same time */}
                  {pdfGenerationDelay ? (
                    <PDFDownloadLink document={<PrintFullPage code={qrCode} />} fileName="FullPageCard.pdf" className={styles.tipCode_printButtonsWrapper__link}>
                      {({ blob, url, loading, error }) => (loading ? (
                        <SpinnerSmall />
                      ) : (
                        <ButtonBase>
                          <div className={styles.tipCode_printButton}>
                            <div className={styles.tipCode_printButton__bold}>{t('badge')}</div>
                            <div className={styles.tipCode_printButton__info}>{t('badge_info')}</div>
                          </div>
                        </ButtonBase>
                      ))}
                    </PDFDownloadLink>
                  ) : (
                    <SpinnerSmall />
                  )}
              </div>
            ) : (
              <div className={`${styles.tipCode_printButtonsWrapper} ${styles.tipCode_printButtonsWrapper__padding}`}>
                <SpinnerSmall />
                <SpinnerSmall />
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default TipCode;
