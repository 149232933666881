// Validation patterns
import iban from 'iban';
import { validatePolish } from 'validate-polish';

// RFC 5322 email address pattern
const eMail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const amount = /^\d+((\.|\,)+(\d{0,2})?$)?$/gi;
const blikCodeMask = [/[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/];
const dayFormat = 'YYYY-MM-DD';
const ALLOWED_SIGN = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ',', '.'];
const passportNumberRegex = /\b[a-zA-Z0-9]{2}[0-9]{5,10}\b/g;

export {
  eMail,
  amount,
  iban,
  blikCodeMask,
  dayFormat,
  ALLOWED_SIGN,
  validatePolish,
  passportNumberRegex,
};
