import React from 'react';
import styles from './PriceStyling.module.scss';

type Props = {
  tip: string | null,
  style?: {
    color?: string,
    integer?: number,
    cents?: number,
    currency?: number,
  },
};

const PriceStyle = (props: Props) => {
  const { tip, style } = props;

  if (tip) {
    const int = tip.split('.')[0];
    const cent = tip.split('.')[1];

    return (
      <div className={`${styles.tipPrice} mt-5`} style={{ color: `${style && style.color}` }}>
        <span className={`inputValue inputValue__int`} style={{ fontSize: `${style && style.integer}rem` }}>{int}</span>

        {tip.includes('.') && <span className={`inputValue ${styles.tipPrice__size22}`}>,</span>}

        <span className="inputValue inputValue__cent" style={{ fontSize: `${style && style.cents}rem` }}>{cent}</span>

        <span className={`inputValue inputValue__currency ${styles.tipPrice__size18}`} style={{ fontSize: `${style && style.currency}rem` }}>zł</span>
      </div>
    );
  }
  return null;
};

export default PriceStyle;
